import {
  ArrowLeft,
  CameraAlt,
  ChatOutlined,
  FilterList,
  Flight,
  Home,
  LocationOn,
  Logout,
  SquareFoot,
  Tv,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem as SelectMenuItem,
  Slider,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { ColorPicker } from "mui-color";
import {
  PointCloudRenderAttributes,
  PointCloudRenderAttributesValueToIndex,
  getPointCloudMaterial,
} from "potree/pointcloud/octree";
import { KeyCodes } from "potree/potree";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { TRANSLATIONS } from "translation.js";
import { logOut } from "utils/request";
import { resourcePath } from "../potree/paths.js";
import { AccessLevel } from "../potree/skyqraft";
import { OverlayMenu, OverlayMenus } from "./overlayMenu.jsx";
import MenuItem from "./widgets/MenuItem";
import { AnnotationBox, ClassifierBox } from "potree/tools/VolumeTool";

const Menus = {
  PROJECTS: 0,
  DISPLAY: 1,
  TOOLS: 2,
  FILTERS: 3,

  LOGOUT: 10,
  HOME: 11,
  ENTRY_LOCATION: 12,

  NONE: -1,
};

class ProjectHeader extends React.Component {
  render() {
    if (window.viewer.isDemoMode()) {
      return <h2>{TRANSLATIONS.EN.MENU.DIRECTORY.Project.Demo}</h2>;
    } else if (this.props.name === "") {
      return (
        <div>
          <h2>{TRANSLATIONS.EN.MENU.DIRECTORY.Project.NoProjectLoaded}</h2>
          <p>
            {TRANSLATIONS.EN.MENU.DIRECTORY.Project.NoProjectLoadedSubHeader}
          </p>
        </div>
      );
    }

    let date_string = "";
    if (window.viewer.sceneData?.properties) {
      const date = window.viewer.sceneData.properties.date;
      if (date instanceof Date && !Number.isNaN(date.valueOf())) {
        date_string = `${
          TRANSLATIONS.EN.MENU.DIRECTORY.Date.Month[date.getMonth()]
        } ${date.getDate()}, ${date.getFullYear()}`;
      }
    }

    return (
      <>
        <h2>{this.props.name}</h2>
        <h3>{this.props.scene_name}</h3>

        {date_string !== "" && (
          <Tooltip
            disableInteractive
            arrow
            placement="top"
            title={TRANSLATIONS.EN.MENU.DIRECTORY.FileDateToolTip}
          >
            <p>{date_string}</p>
          </Tooltip>
        )}
      </>
    );
  }
}

class Scene extends React.Component {
  loadScene() {
    window.viewer.targetLocation = {
      type: "NONE",
    };
    window.viewer.loadScene(this.props.scene);
  }

  render() {
    return (
      <Button onClick={this.loadScene.bind(this)} variant="text">
        {this.props.name}
      </Button>
    );
  }
}

class Scenes extends React.Component {
  render() {
    let demo_mode_incrementor = 1;
    const scenes = this.props.scenes.map((scene) => (
      <Scene
        key={scene.name}
        scene={scene.name}
        name={window.viewer.isDemoMode() ? demo_mode_incrementor++ : scene.name}
      />
    ));

    return (
      <>
        <h2>{TRANSLATIONS.EN.MENU.DIRECTORY.Scenes}</h2>
        <div className="flex-column" style={{ padding: "10px" }}>
          {scenes}
        </div>
      </>
    );
  }
}

class ProjectsMenu extends React.Component {
  componentDidMount() {
    window.viewer.addEventListener("project_loaded", this.onProjectLoaded);
  }
  componentWillUnmount() {
    window.viewer.removeEventListener("project_loaded", this.onProjectLoaded);
  }

  onProjectLoaded = () => {
    this.forceUpdate();
  };

  render() {
    const scenes = [];
    let currentMission = "";
    if (window.viewer?.projectData?.name) {
      for (const key of Object.values(window.viewer.projectData.zones)) {
        scenes.push({ name: key });
      }
      currentMission = window.viewer.projectData.name;

      scenes.sort((a, b) => a.name.localeCompare(b.name));
    }

    let clearanceSystem = "";
    const scene_name = window.viewer.getScene();
    if (window.viewer?.sceneData?.clearance_system) {
      clearanceSystem = window.viewer.sceneData.clearance_system.name;
    }

    let has_clearance = true;
    const properties = window.viewer.sceneData?.properties;
    if (properties) {
      has_clearance = properties.has_clearance;
    }

    return (
      <div>
        <ProjectHeader name={currentMission} scene_name={scene_name} />
        <hr />
        {has_clearance && (
          <>
            <p>
              <Tooltip
                disableInteractive
                arrow
                placement="top"
                title={TRANSLATIONS.EN.MENU.DIRECTORY.ClearanceSystemTooltip}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    this.props.setOverlay(OverlayMenus.CLEARANCE);
                  }}
                >
                  <span>
                    <b>{TRANSLATIONS.EN.MENU.DIRECTORY.ClearanceSystem}</b>:
                  </span>{" "}
                  <span>{clearanceSystem}</span>
                </Button>
              </Tooltip>
            </p>
            <hr />
          </>
        )}
        <Scenes scenes={scenes} />
      </div>
    );
  }
}

class DisplayMenu extends React.Component {
  onChangeBudget(value) {
    window.viewer.setPointBudget(value);
  }
  onChangeFoV(value) {
    window.viewer.setFOV(value);
  }
  onChangeMinNodeSize(value) {
    window.viewer.setMinNodeSize(value);
  }

  toggleImages() {
    const active = window.viewer.getImageObjectTool().active;
    window.viewer.getImageObjectTool().setActive(!active);
    this.forceUpdate();
  }
  toggleEntrypoint() {
    const active = window.viewer.lineRenderer.active;
    window.viewer.lineRenderer.active = !active;
    this.forceUpdate();
  }
  toggleBoundingBoxes() {
    const active = window.viewer.getShowBoundingBox();
    window.viewer.setShowBoundingBox(!active);
    this.forceUpdate();
  }

  render() {
    return (
      <div>
        <h2>{TRANSLATIONS.EN.MENU.DISPLAY.Header}</h2>
        <hr />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={window.viewer.getImageObjectTool().active}
              onClick={() => this.toggleImages()}
            />
          }
          label={<h4>Display Images</h4>}
        />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={window.viewer.lineRenderer.active}
              onClick={() => this.toggleEntrypoint()}
            />
          }
          label={<h4>Display Entrypoint</h4>}
        />
        <hr />
        <span>{TRANSLATIONS.EN.MENU.DISPLAY.PointBudget}</span>
        <Slider
          defaultValue={window.viewer.getPointBudget()}
          min={100000}
          max={10000000}
          step={1000}
          onChange={(_, val) => this.onChangeBudget(val)}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <span>{TRANSLATIONS.EN.MENU.DISPLAY.FieldOfView}</span>
        <Slider
          defaultValue={window.viewer.getFOV()}
          min={20}
          max={100}
          step={1}
          onChange={(_, val) => this.onChangeFoV(val)}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <span>{TRANSLATIONS.EN.MENU.DISPLAY.MinPointSize}</span>
        <Slider
          defaultValue={window.viewer.getMinNodeSize()}
          min={1}
          max={800}
          step={1}
          onChange={(_, val) => this.onChangeMinNodeSize(val)}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <hr />
        <EyeDomeLighting enabled={window.viewer.getEDLEnabled()} />
        <hr />
        {window.viewer.hasAccessLevel(4) && (
          <div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={window.viewer.isDemoMode()}
                    onClick={() => {
                      window.viewer.setDemoMode(!window.viewer.isDemoMode());
                      this.forceUpdate();
                    }}
                  />
                }
                label={<span>{TRANSLATIONS.EN.MENU.DISPLAY.DemoMode}</span>}
              />
            </FormGroup>
          </div>
        )}
        {window.viewer.getDisplayLevel() < AccessLevel.CUSTOMER && (
          <div>
            <hr />
            <h4>{TRANSLATIONS.EN.MENU.DISPLAY.Technical.Header}</h4>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={window.viewer.getShowBoundingBox()}
                    onClick={() => this.toggleBoundingBoxes()}
                  />
                }
                label={
                  <span>
                    {TRANSLATIONS.EN.MENU.DISPLAY.Technical.ShowBoundingBoxes}
                  </span>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={window.viewer.debugTools}
                    onClick={() => {
                      window.viewer.debugTools = !window.viewer.debugTools;
                      this.forceUpdate();
                    }}
                  />
                }
                label={
                  <span>
                    {TRANSLATIONS.EN.MENU.DISPLAY.Technical.DebugPrint}
                  </span>
                }
              />
            </FormGroup>
          </div>
        )}
      </div>
    );
  }
}

class EyeDomeLighting extends React.Component {
  toggleEdl() {
    window.viewer.setEDLEnabled(!window.viewer.getEDLEnabled());
    this.forceUpdate();
  }

  onChangeRadius(value) {
    window.viewer.setEDLRadius(value);
  }
  onChangeStrength(value) {
    window.viewer.setEDLStrength(value);
  }
  onChangeOpacity(value) {
    window.viewer.setEDLOpacity(value);
  }

  render() {
    return (
      <div>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={window.viewer.getEDLEnabled()}
                onClick={() => this.toggleEdl()}
              />
            }
            label={<span>Eye-Dome Lighting</span>}
          />
        </FormGroup>
        {window.viewer.getEDLEnabled() && (
          <div>
            <span>Radius</span>
            <Slider
              defaultValue={window.viewer.getEDLRadius()}
              min={1}
              max={4}
              step={0.01}
              onChange={(_, val) => this.onChangeRadius(val)}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
            <span>Strength</span>
            <Slider
              defaultValue={window.viewer.getEDLStrength()}
              min={0}
              max={5}
              step={0.01}
              onChange={(_, val) => this.onChangeStrength(val)}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
            <span>Opacity</span>
            <Slider
              defaultValue={window.viewer.getEDLOpacity()}
              min={0}
              max={1}
              step={0.01}
              onChange={(_, val) => this.onChangeOpacity(val)}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
          </div>
        )}
      </div>
    );
  }
}

class FiltersMenu extends React.Component {
  componentDidMount() {
    window.viewer.addEventListener(
      "classifications_changed",
      this.classificationsChanged
    );
  }
  componentWillUnmount() {
    window.viewer.removeEventListener(
      "classifications_changed",
      this.classificationsChanged
    );
  }

  classificationsChanged = () => {
    this.forceUpdate();
  };

  currentRenderType() {
    const pointcloudMaterial = getPointCloudMaterial();
    if (!pointcloudMaterial) {
      return "highlight";
    }

    switch (pointcloudMaterial.activeAttributeName) {
      case PointCloudRenderAttributes.RAW_COLOR:
        return "rgba";
      case PointCloudRenderAttributes.CLEARANCE:
        return "clearance";
      case PointCloudRenderAttributes.CLASSIFICATION:
        return "classification";
      default:
        return "highlight";
    }
  }

  setRenderType(event) {
    let render_type = "";
    let color_mode = 0;
    switch (event.target.value) {
      case "clearance":
        render_type = PointCloudRenderAttributes.CLEARANCE;
        color_mode = PointCloudRenderAttributesValueToIndex.CLEARANCE;
        break;
      case "classification":
        render_type = PointCloudRenderAttributes.CLASSIFICATION;
        color_mode = PointCloudRenderAttributesValueToIndex.CLASSIFICATION;
        break;
      case "rgba":
        render_type = PointCloudRenderAttributes.RAW_COLOR;
        color_mode = PointCloudRenderAttributesValueToIndex.RAW_COLOR;
        break;
      default:
        render_type = PointCloudRenderAttributes.HIGHLIGHT;
        color_mode = PointCloudRenderAttributesValueToIndex.HIGHLIGHT;
        break;
    }

    window.viewer.filterColorMode = color_mode;

    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set("filter", window.viewer.filterColorMode);

    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();

    window.history.replaceState(
      {
        path: url,
      },
      "",
      url
    );
    const pointcloudMaterial = getPointCloudMaterial();
    if (!!pointcloudMaterial) {
      pointcloudMaterial.activeAttributeName = render_type;
    }
    this.forceUpdate();
  }

  toggleAllClassifications() {
    window.viewer.toggleAllClassificationsVisibility();
    this.forceUpdate();
  }
  toggleClass(key) {
    window.viewer.setClassificationVisibility(
      key,
      !window.viewer.classifications[key].visible
    );
    this.forceUpdate();
  }

  onClassColorChange(raw_color, class_key) {
    let color = [
      raw_color.rgb[0] / 255.0,
      raw_color.rgb[1] / 255.0,
      raw_color.rgb[2] / 255.0,
      1.0,
    ];

    window.viewer.classifications[class_key].color = color;
    this.forceUpdate();
  }

  isVegetationActive() {
    return window.viewer.classifications[1].visible;
  }
  toggleVegetationActive() {
    window.viewer.setClassificationVisibility(
      1,
      !window.viewer.classifications[1].visible
    );
    this.forceUpdate();
  }
  isGroundActive() {
    return window.viewer.classifications[2].visible;
  }
  toggleGroundActive() {
    window.viewer.setClassificationVisibility(
      2,
      !window.viewer.classifications[2].visible
    );
    this.forceUpdate();
  }

  toggleDisplayClearance(value) {
    window.viewer.toggleDisplayClearance(value);

    this.forceUpdate();
  }

  renderClassifications() {
    const categories = [];
    const displayColorPickers = this.currentRenderType() === "classification";

    const sceneData = window.viewer.sceneData;
    if (!sceneData.categories) {
      return [];
    }

    for (const category_id of sceneData.categories.order) {
      const category_value = sceneData.categories.entries[category_id];

      const classes = [];
      for (const key of category_value.entries) {
        const value = sceneData.classes[key];

        // Our locally modifiable value, this is what is used to render.
        const local_value = window.viewer.classifications[key];
        const class_color = local_value.color;

        classes.push(
          <div className="flex-fill-view" key={key + "_li"}>
            <Switch
              color="primary"
              checked={local_value.visible}
              onClick={() => this.toggleClass(key)}
            />
            <span>{value.name}</span>
            {displayColorPickers && (
              <div className="flex-end-align">
                <ColorPicker
                  value={
                    "rgba(" +
                    class_color[0] * 255 +
                    "," +
                    class_color[1] * 255 +
                    "," +
                    class_color[2] * 255 +
                    ")"
                  }
                  hideTextfield
                  disableAlpha
                  onChange={(color) => this.onClassColorChange(color, key)}
                />
              </div>
            )}
          </div>
        );
      }

      categories.push(
        <React.Fragment key={category_value.name + "_li"}>
          <div className="flex-fill-view">
            <b>{category_value.name}</b>
          </div>

          {classes}
        </React.Fragment>
      );
    }

    return categories;
  }

  render() {
    let no_color = false;
    let no_clearance = false;
    let internally_classified = false;

    const properties = window.viewer.sceneData?.properties;
    if (properties) {
      no_color = !properties.has_color;
      no_clearance = !properties.has_clearance;
      internally_classified = properties.internally_classified;
    }

    const classifications = this.renderClassifications();
    const all_classes_visible = Object.values(
      window.viewer.classifications
    ).every((item) => item.visible);
    const current_render_type = this.currentRenderType();

    const displayed_clearance = window.viewer.getDisplayedClearance();
    let clearances = [];
    if (window.viewer?.sceneData?.clearance_system) {
      clearances = Object.entries(
        window.viewer.sceneData.clearance_system["axes"]
      ).map(([key, value]) => {
        let int_key = Number(key);
        return (
          <div className="flex-fill-view" key={key + "_li"}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={displayed_clearance.has(int_key)}
                  onClick={() => this.toggleDisplayClearance(int_key)}
                />
              }
              label={<i>{value.name}</i>}
            />
          </div>
        );
      });
    }

    return (
      <div>
        <h2>{TRANSLATIONS.EN.MENU.FILTERS.Header}</h2>
        <hr />
        <h3>{TRANSLATIONS.EN.MENU.FILTERS.Coloration.Header}</h3>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={current_render_type}
          name="radio-buttons-group"
          onChange={this.setRenderType.bind(this)}
        >
          <Tooltip
            disableInteractive
            arrow
            placement="top-start"
            title={
              !no_color
                ? TRANSLATIONS.EN.MENU.FILTERS.Coloration.RawColorTooltip
                : TRANSLATIONS.EN.MENU.FILTERS.Coloration
                    .RawColorTooltipMissingColor
            }
          >
            <FormControlLabel
              value="rgba"
              control={<Radio />}
              label={TRANSLATIONS.EN.MENU.FILTERS.Coloration.RawColor}
              disabled={no_color}
            />
          </Tooltip>
          <Tooltip
            disableInteractive
            arrow
            placement="top-start"
            title={
              !no_color
                ? TRANSLATIONS.EN.MENU.FILTERS.Coloration.HighlightedTooltip
                : TRANSLATIONS.EN.MENU.FILTERS.Coloration
                    .HighlightedTooltipMissingColor
            }
          >
            <FormControlLabel
              value="highlight"
              control={<Radio />}
              label={TRANSLATIONS.EN.MENU.FILTERS.Coloration.Highlighted}
              disabled={no_color}
            />
          </Tooltip>
          <div>
            <Tooltip
              disableInteractive
              arrow
              placement="top-start"
              title={
                !no_clearance
                  ? TRANSLATIONS.EN.MENU.FILTERS.Coloration.ClearanceTooltip
                  : TRANSLATIONS.EN.MENU.FILTERS.Coloration
                      .ClearanceTooptipMissingClearance
              }
            >
              <FormControlLabel
                value="clearance"
                control={<Radio />}
                label={TRANSLATIONS.EN.MENU.FILTERS.Coloration.Clearance}
                disabled={no_clearance}
              />
            </Tooltip>
            {!no_clearance && (
              <Tooltip
                disableInteractive
                arrow
                placement="top"
                title={TRANSLATIONS.EN.MENU.DIRECTORY.ClearanceSystemTooltip}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    this.props.setOverlay(OverlayMenus.CLEARANCE);
                  }}
                >
                  <span>→</span>
                </Button>
              </Tooltip>
            )}
          </div>
          {current_render_type === "clearance" && (
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={displayed_clearance}
              name="radio-buttons-group"
              onChange={(event) => this.toggleDisplayClearance(event)}
            >
              <div className="flex-column background-odd offset-left">
                {clearances}
              </div>
            </RadioGroup>
          )}
          <Tooltip
            disableInteractive
            arrow
            placement="top-start"
            title={
              TRANSLATIONS.EN.MENU.FILTERS.Coloration.ClassificationsTooltip
            }
          >
            <FormControlLabel
              value="classification"
              control={<Radio />}
              label={TRANSLATIONS.EN.MENU.FILTERS.Coloration.Classifications}
            />
          </Tooltip>
          <p>
            <i>
              {TRANSLATIONS.EN.MENU.FILTERS.ClassificationSource}:{" "}
              {internally_classified
                ? TRANSLATIONS.EN.MENU.FILTERS.SourceSkyqraft
                : TRANSLATIONS.EN.MENU.FILTERS.SourceExternal}
            </i>
          </p>
        </RadioGroup>
        <h3>{TRANSLATIONS.EN.MENU.FILTERS.Classifications.Header}</h3>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={all_classes_visible}
                onClick={() => {
                  this.toggleAllClassifications();
                }}
              />
            }
            label={
              <span>
                {TRANSLATIONS.EN.MENU.FILTERS.Classifications.ToggleAll}
              </span>
            }
          />
        </FormGroup>

        <div className="flex-column background-odd">{classifications}</div>
      </div>
    );
  }
}

class Classifiers extends React.Component {
  #classificationTools = [
    {
      icon: "/icons/clip_volume.svg",
      type: 1,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipOther,
      keycode: KeyCodes.Q,
    },
    {
      icon: "/icons/ground.svg",
      type: 2,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipGround,
      keycode: KeyCodes.W,
    },
    {
      icon: "/icons/wire.svg",
      type: 3,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipWire,
      keycode: KeyCodes.E,
    },
    {
      icon: "/icons/pole.svg",
      type: 4,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipPole,
      keycode: KeyCodes.R,
    },
    {
      icon: "/icons/other_wire.svg",
      type: 5,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipOtherWire,
      keycode: KeyCodes.A,
    },
    {
      icon: "/icons/other_pole.svg",
      type: 6,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipOtherPole,
      keycode: KeyCodes.S,
    },
    {
      icon: "/icons/power_elements.svg",
      type: 8,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipPowerlineOther,
      keycode: KeyCodes.D,
    },
    {
      icon: "/icons/home.png",
      type: 13,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipBuilding,
      keycode: KeyCodes.F,
    },
    // ANNOTATION LEFT OUT.
    {
      icon: "/icons/gradients_spectral.png",
      type: 10,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipLowVegetation,
      keycode: KeyCodes.Z,
    },
    {
      icon: "/icons/gradients_yellow_green.png",
      type: 11,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipMediumVegetation,
      keycode: KeyCodes.X,
    },
    {
      icon: "/icons/gradients_plasma.png",
      type: 12,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipHighVegetation,
      keycode: KeyCodes.C,
    },
    {
      icon: "/icons/rgb_elevation.png",
      type: 14,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipNoise,
      keycode: KeyCodes.V,
    },
    {
      icon: "/icons/bottom.svg",
      type: 15,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipWater,
    },
    {
      icon: "/icons/translate.svg",
      type: 16,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipGuyWire,
    },
    {
      icon: "/icons/map_icon.png",
      type: 17,
      tooltip: TRANSLATIONS.EN.CLASSIFIERS.ClipRoad,
    },
  ];

  componentDidMount() {
    window.viewer.inputHandler.addEventListener("keydown", this.onKeyDown);
  }
  componentWillUnmount() {
    window.viewer.inputHandler.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e) => {
    if (e.shiftKey) {
      const classifier = this.#classificationTools.find(
        (entry) => (entry.keycode ?? null) === e.keyCode
      );

      if (classifier) {
        this.onClickClassifier(classifier.type);

        const message = `Selected ${
          window.viewer.globalData.lidar_types[classifier.type].name
        }`;

        toast.success(message);
      }
    }
  };

  onClickClassifier(type) {
    window.viewer.volumeTool.insertClassifier(type);
  }

  render() {
    const classifiers = this.#classificationTools.map((task) => {
      let tooltip = task.tooltip;
      if (!!task.keycode) {
        tooltip += " [Shift + " + String.fromCharCode(task.keycode) + "]";
      }

      return (
        <Tooltip
          key={task.type + "_classifier"}
          disableInteractive
          arrow
          placement="top"
          title={tooltip}
        >
          <img
            onDragStart={() => false}
            className="button-icon"
            src={resourcePath + task.icon}
            draggable={false}
            alt={task.tooltip}
            onClick={() => this.onClickClassifier(task.type)}
          />
        </Tooltip>
      );
    });

    return (
      <div>
        <h3>Classifiers</h3>
        <div className="multi-link-view">{classifiers}</div>
        <hr />
      </div>
    );
  }
}

const Annotations = () => {
  const [displayAnnotationTypes, setDisplayAnnotationTypes] = useState(false);
  const [annotationTypes, setAnnotationTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    const onGlobalDataLoaded = () => {
      const newTypes = Object.entries(
        window.viewer.globalData["object_types"]
      ).map(([key, val]) => ({
        id: key,
        name: val.name,
      }));

      setAnnotationTypes(newTypes);
    };
    // In case this opens after the global data has been loaded we need to backfill it.
    if (window.viewer?.globalData?.object_types) {
      onGlobalDataLoaded();
    }
    window.viewer.addEventListener("loaded_global_data", onGlobalDataLoaded);

    const updateSelection = (selection) => {
      const selectedAnnotationVolume = selection.find(
        (volume) => volume.isAnnotation
      );
      if (!selectedAnnotationVolume) {
        return;
      }

      setSelectedType(selectedAnnotationVolume.annotation_type);
    };
    const onSelectionChanged = (e) => {
      updateSelection(e.selection);
    };

    updateSelection(window.viewer.inputHandler.selection);

    window.viewer.inputHandler.addEventListener(
      "selection_changed",
      onSelectionChanged
    );

    return () => {
      window.viewer.removeEventListener(
        "loaded_global_data",
        onGlobalDataLoaded
      );
      window.viewer.inputHandler.removeEventListener(
        "selection_changed",
        onSelectionChanged
      );
    };
  }, []);

  const setAnnotationType = (type_id) => {
    window.viewer.inputHandler.selection.forEach((selection) => {
      if (selection instanceof AnnotationBox) {
        selection.setAnnotationType(type_id);
      }
    });

    setSelectedType(type_id);
  };

  let selectedAnnotationTypeName = TRANSLATIONS.EN.DEFECTS.Fallback;
  if (window.viewer?.globalData?.object_types[selectedType]) {
    selectedAnnotationTypeName =
      window.viewer.globalData.object_types[selectedType].name;
  }

  return (
    <div>
      <div className="flex-fill-view">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setDisplayAnnotationTypes(!displayAnnotationTypes)}
        >
          {selectedAnnotationTypeName}
        </Button>
      </div>

      <div className="multi-link-view view-two">
        {displayAnnotationTypes &&
          annotationTypes.map((value) => (
            <Button
              key={value.id}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setAnnotationType(value.id);
                setDisplayAnnotationTypes(!displayAnnotationTypes);
              }}
            >
              {value.name}
            </Button>
          ))}
      </div>
    </div>
  );
};

class VolumeClassifiers extends React.Component {
  componentDidMount() {
    window.viewer.inputHandler.addEventListener(
      "selection_changed",
      this.onSelectionChanged
    );
  }
  componentWillUnmount() {
    window.viewer.inputHandler.removeEventListener(
      "selection_changed",
      this.onSelectionChanged
    );
  }

  onSelectionChanged = () => {
    this.forceUpdate();
  };

  onFromTypeChanged(e) {
    window.viewer.inputHandler.selection[0].type_from = Number(e.target.value);
    this.forceUpdate();
  }
  onToTypeChanged(e) {
    window.viewer.inputHandler.selection[0].type_to = Number(e.target.value);
    this.forceUpdate();
  }

  render() {
    if (!(window.viewer.inputHandler.selection[0] instanceof ClassifierBox)) {
      return <></>;
    }

    const lidar_types = Object.entries(
      window.viewer.globalData.lidar_types
    ).map(([key, value]) => {
      return <SelectMenuItem value={String(key)}>{value.name}</SelectMenuItem>;
    });
    const from_type_value = String(
      window.viewer.inputHandler.selection[0].type_from
    );
    const to_type_value = String(
      window.viewer.inputHandler.selection[0].type_to
    );

    return (
      <div>
        <FormControl fullWidth>
          <InputLabel>From Type</InputLabel>
          <Select
            value={from_type_value}
            label="From Type"
            onChange={this.onFromTypeChanged.bind(this)}
          >
            <SelectMenuItem value={""}>
              <em>None</em>
            </SelectMenuItem>
            {lidar_types}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>To Type</InputLabel>
          <Select
            value={to_type_value}
            label="To Type"
            onChange={this.onToTypeChanged.bind(this)}
          >
            {lidar_types}
          </Select>
        </FormControl>
      </div>
    );
  }
}

class FlightControls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      movement_speed: this.toExponentialMovementSpeed(
        window.viewer.getMoveSpeed()
      ),
    };
  }

  componentDidMount() {
    window.viewer.addEventListener(
      "move_speed_changed",
      this.onMoveSpeedChanged
    );
  }
  componentWillUnmount() {
    window.viewer.removeEventListener(
      "move_speed_changed",
      this.onMoveSpeedChanged
    );
  }
  onMoveSpeedChanged = () => {
    this.setState({
      movement_speed: this.toExponentialMovementSpeed(
        window.viewer.getMoveSpeed()
      ),
    });
  };

  #speedRangeMin = 1;
  #speedRangeMax = 10_000;
  toLinearMovementSpeed(value) {
    return Math.pow(value, 4) * this.#speedRangeMax + this.#speedRangeMin;
  }

  toExponentialMovementSpeed(value) {
    return Math.pow((value - this.#speedRangeMin) / this.#speedRangeMax, 1 / 4);
  }

  render() {
    return (
      <div>
        <h4>Movement Speed</h4>
        <Slider
          value={this.state.movement_speed}
          min={0}
          step={0.01}
          max={1}
          scale={(value) => Math.round(this.toLinearMovementSpeed(value))}
          onChange={(event) =>
            window.viewer.setMoveSpeed(
              this.toLinearMovementSpeed(event.target.value)
            )
          }
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
      </div>
    );
  }
}

class EarthControls extends React.Component {
  #zoomMultiplierMin = 0.1;
  #zoomMultiplierMax = 5.0;

  render() {
    return (
      <div>
        <h4>Zoom Speed</h4>
        <Slider
          value={window.viewer.earthControls.zoomMultiplier}
          min={this.#zoomMultiplierMin}
          step={0.1}
          max={this.#zoomMultiplierMax}
          onChange={(event) => {
            window.viewer.earthControls.zoomMultiplier = event.target.value;
            this.forceUpdate();
          }}
          valueLabelDisplay="auto"
        />
      </div>
    );
  }
}

const ToolsMenu = ({ setMenu }) => {
  const [selection, setSelection] = useState(
    window.viewer.inputHandler.selection
  );
  const [activeControls, setActiveControls] = useState(
    typeof window.viewer.controls
  );
  const [isRedirect, setIsRedirect] = useState(window.viewer.isRedirected());

  useEffect(() => {
    const updateSelection = () => {
      setSelection([...window.viewer.inputHandler.selection]);
    };

    const onKeyDown = (e) => {
      if (e.ctrlKey) {
        if (e.keyCode === KeyCodes.S) {
          window.viewer.volumeTool.saveToDatabase();
        } else if (e.keyCode === KeyCodes.E) {
          window.viewer.volumeTool.loadFromDatabase();
        }
      }
    };

    window.viewer.inputHandler.addEventListener(
      "selection_changed",
      updateSelection
    );
    window.viewer.addEventListener("project_loaded", updateSelection);
    window.viewer.inputHandler.addEventListener("keydown", onKeyDown);

    const onControlsChanged = (event) => {
      if (event.controls === window.viewer.fpControls) {
        setActiveControls("firstPerson");
      } else if (event.controls === window.viewer.earthControls) {
        setActiveControls("earth");
      } else {
        setActiveControls("none");
      }
    };
    window.viewer.addEventListener("controls_changed", onControlsChanged);

    const onSceneLoaded = () => {
      setIsRedirect(window.viewer.isRedirected());
    };
    window.viewer.addEventListener("scene_loaded", onSceneLoaded);

    return () => {
      window.viewer.inputHandler.removeEventListener(
        "selection_changed",
        updateSelection
      );
      window.viewer.removeEventListener("project_loaded", updateSelection);
      window.viewer.inputHandler.removeEventListener("keydown", onKeyDown);
      window.viewer.removeEventListener("controls_changed", onControlsChanged);
    };
  }, []);

  const onUnitChange = (event) => {
    window.viewer.setDisplayUnit(event.target.value);
  };

  const renderSelectedVolume = () => {
    if (selection.length > 0) {
      if (selection[0] instanceof AnnotationBox) {
        return <Annotations />;
      } else if (selection[0].isClassifier) {
        return <VolumeClassifiers />;
      }
    }
    return <></>;
  };

  return (
    <div>
      <h2>{TRANSLATIONS.EN.MENU.TOOLS.Header}</h2>
      <hr />
      <h3>{TRANSLATIONS.EN.MENU.TOOLS.Measures.Header}</h3>
      <div className="multi-link-view">
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Measures.Distance}
        >
          <img
            draggable="false"
            className="button-icon"
            src={resourcePath + "/icons/distance.svg"}
            alt={"tt.distance_measurement"}
            onClick={() => {
              window.viewer.measuringTool.startInsertion({
                showDistances: true,
                closed: false,
                name: "Distance",
              });
              // Close menu when activating tool.
              setMenu(Menus.NONE);
            }}
          />
        </Tooltip>
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Measures.Height}
        >
          <img
            draggable="false"
            className="button-icon"
            src={resourcePath + "/icons/height.svg"}
            alt={"tt.height_measurement"}
            onClick={() => {
              window.viewer.measuringTool.startInsertion({
                showDistances: false,
                showHeight: true,
                closed: false,
                maxMarkers: 2,
                name: "Height",
              });
              // Close menu when activating tool.
              setMenu(Menus.NONE);
            }}
          />
        </Tooltip>
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Measures.RemoveAll}
        >
          <img
            draggable="false"
            className="button-icon"
            src={resourcePath + "/icons/remove.svg"}
            alt={"tt.remove_all_measurement"}
            onClick={() => {
              window.viewer.sceneContext.removeAllMeasurements();
            }}
          />
        </Tooltip>
      </div>
      <br />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {TRANSLATIONS.EN.MENU.TOOLS.Units.Header}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue="m"
          label="Unit"
          onChange={(e) => onUnitChange(e)}
        >
          <SelectMenuItem value="m">
            {TRANSLATIONS.EN.MENU.TOOLS.Units.Meter}
          </SelectMenuItem>
          <SelectMenuItem value="ft">
            {TRANSLATIONS.EN.MENU.TOOLS.Units.Feet}
          </SelectMenuItem>
          <SelectMenuItem value="in">
            {TRANSLATIONS.EN.MENU.TOOLS.Units.Inch}
          </SelectMenuItem>
        </Select>
      </FormControl>
      <hr />
      <h3>{TRANSLATIONS.EN.MENU.TOOLS.Navigation.Header}</h3>

      <div className="multi-link-view view-four">
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.Earth}
        >
          <img
            draggable="false"
            className="button-icon"
            alt={TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.Earth}
            src={resourcePath + "/icons/earth_controls_1.png"}
            onClick={() => {
              window.viewer.setControls(window.viewer.earthControls);
            }}
          />
        </Tooltip>
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.Flight}
        >
          <img
            draggable="false"
            className="button-icon"
            alt={TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.Flight}
            src={resourcePath + "/icons/fps_controls.svg"}
            onClick={() => {
              window.viewer.setControls(window.viewer.fpControls);
              window.viewer.fpControls.lockElevation = false;
            }}
          />
        </Tooltip>
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.Home}
        >
          <Home
            draggable="false"
            onClick={() => window.viewer.fitToScreen()}
            sx={{ width: "48px", height: "48px", cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip
          disableInteractive
          arrow
          placement="top"
          title={TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.EntryLocation}
        >
          <LocationOn
            draggable="false"
            onClick={() => window.viewer.goToHomeView()}
            sx={{ width: "48px", height: "48px", cursor: "pointer" }}
          />
        </Tooltip>
      </div>
      {activeControls === "firstPerson" && <FlightControls />}
      {activeControls === "earth" && <EarthControls />}
      <hr />
      <h3>{TRANSLATIONS.EN.MENU.TOOLS.Volumes.Header}</h3>
      {!isRedirect && (
        <>
          <div className="multi-link-view view-two">
            <Tooltip
              disableInteractive
              arrow
              placement="top"
              title={TRANSLATIONS.EN.DEFECTS.Tooltip}
            >
              <img
                className="button-icon"
                src={`${resourcePath}/icons/clip_volume.svg`}
                alt="tt.clip_annotation"
                onClick={() => window.viewer.volumeTool.insertAnnotation()}
              />
            </Tooltip>
            <Tooltip
              disableInteractive
              arrow
              placement="top"
              title={TRANSLATIONS.EN.SCREENSHOT.Tooltip}
            >
              <CameraAlt
                draggable="false"
                className="button-icon"
                onClick={() => {
                  window.viewer.screenshotTool.startScreenshot();

                  // Close menu when activating tool.
                  setMenu(Menus.NONE);
                }}
              />
            </Tooltip>
          </div>
          {renderSelectedVolume()}
          {window.viewer.getDisplayLevel() < AccessLevel.CUSTOMER && (
            <Classifiers />
          )}
        </>
      )}
      {isRedirect && <p>{TRANSLATIONS.EN.REDIRECT.NO_VOLUMES}</p>}

      {!isRedirect && (
        <div className="flex-fill-view">
          <Button
            variant="text"
            onClick={() => window.viewer.volumeTool.saveToDatabase()}
          >
            Save
          </Button>
          <Button
            variant="text"
            onClick={() => window.viewer.volumeTool.loadFromDatabase()}
          >
            Load
          </Button>
        </div>
      )}
    </div>
  );
};

export const Menu = (props) => {
  const [active, setActive] = useState(
    props.start_open ? Menus.PROJECTS : Menus.NONE
  );
  const [overlayMenu, setOverlayMenu] = useState(OverlayMenus.NONE);

  useEffect(() => {
    const onSceneLoad = () => setActive(Menus.NONE);
    const onSceneClick = () => {
      // When have a volume selected, we want to keep the tools menu open.
      // Mostly because we can change the type of volumes...
      if (
        Number(active) !== Menus.TOOLS ||
        window.viewer.inputHandler.selection.length === 0
      ) {
        setActive(Menus.NONE);
      }
    };

    window.viewer.addEventListener("scene_loaded", onSceneLoad);
    window.viewer.renderContext.canvas.addEventListener(
      "mousedown",
      onSceneClick
    );

    return () => {
      window.viewer.removeEventListener("scene_loaded", onSceneLoad);
      window.viewer.renderContext.canvas.removeEventListener(
        "mousedown",
        onSceneClick
      );
    };
  }, [active]);

  const onItemClick = (event) => {
    const selectedId = Number(event.target.id);
    if (selectedId === Menus.LOGOUT) {
      if (window.confirm(TRANSLATIONS.EN.LOG_OUT.Confirm)) {
        logOut();
      }
    } else if (selectedId === Menus.HOME) {
      window.viewer.fitToScreen();
    } else if (selectedId === Menus.ENTRY_LOCATION) {
      window.viewer.goToHomeView();
    } else if (selectedId === active) {
      setActive(Menus.NONE);
    } else {
      setActive(selectedId);
    }
  };

  const renderSidebarContainerContent = () => {
    switch (Number(active)) {
      case Menus.PROJECTS:
        return (
          <ProjectsMenu
            scenes={props.scenes}
            missions={props.missions}
            mission={props.mission}
            setOverlay={setOverlayMenu}
          />
        );
      case Menus.DISPLAY:
        return <DisplayMenu />;
      case Menus.TOOLS:
        // ToolsMenu gets special treatment
        // Since it wants to close the menu when you activate a tool.
        return <ToolsMenu setMenu={setActive} />;
      case Menus.FILTERS:
        return <FiltersMenu setOverlay={setOverlayMenu} />;
      default:
        return <div></div>;
    }
  };

  const topMenuEntries = [
    {
      id: Menus.PROJECTS,
      tooltip: TRANSLATIONS.EN.MENU.TOOLTIP.directory,
      icon: <Flight draggable="false" />,
    },
    {
      id: Menus.FILTERS,
      tooltip: TRANSLATIONS.EN.MENU.TOOLTIP.filters,
      icon: <FilterList draggable="false" />,
    },
    {
      id: Menus.TOOLS,
      tooltip: TRANSLATIONS.EN.MENU.TOOLTIP.tools,
      icon: <SquareFoot draggable="false" />,
    },
    {
      id: Menus.DISPLAY,
      tooltip: TRANSLATIONS.EN.MENU.TOOLTIP.display,
      icon: <Tv draggable="false" />,
    },
    {
      id: Menus.HOME,
      tooltip: TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.Home,
      icon: <Home draggable="false" />,
    },
    {
      id: Menus.ENTRY_LOCATION,
      tooltip: TRANSLATIONS.EN.MENU.TOOLS.Navigation.Controls.EntryLocation,
      icon: <LocationOn draggable="false" />,
    },
  ];

  const bottomMenuEntries = [
    {
      id: Menus.LOGOUT,
      tooltip: TRANSLATIONS.EN.MENU.TOOLTIP.log_out,
      icon: <Logout draggable="false" />,
    },
  ];

  return (
    <>
      <div id="primary_menu">
        <div id="top_menu">
          <div className="imageWrapper">
            <img alt="Arkion" src={"logo_50px_blue.png"} />
          </div>
          {topMenuEntries.map((entry) => (
            <MenuItem
              key={entry.id}
              id={entry.id}
              tooltip={entry.tooltip}
              active={active === entry.id}
              onClick={onItemClick}
            >
              {entry.icon}
            </MenuItem>
          ))}
        </div>
        <div id="bottom_menu">
          {bottomMenuEntries.map((entry) => (
            <MenuItem
              key={entry.id}
              id={entry.id}
              tooltip={entry.tooltip}
              active={active === entry.id}
              onClick={onItemClick}
            >
              {entry.icon}
            </MenuItem>
          ))}
        </div>
      </div>
      {overlayMenu !== OverlayMenus.NONE && (
        <OverlayMenu
          active={overlayMenu}
          onClose={() => setOverlayMenu(OverlayMenus.NONE)}
        />
      )}
      {active !== Menus.NONE && (
        <div id="potree_sidebar_container">
          <div id="close_menu" onClick={() => setActive(Menus.NONE)}>
            <ArrowLeft />
            <p>{TRANSLATIONS.EN.MENU.CloseMenu}</p>
          </div>
          <hr />
          {renderSidebarContainerContent()}
        </div>
      )}
    </>
  );
};
