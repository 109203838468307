import { useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "authentication/Login";
import CheckingCredentials from "./CheckingCredentials";
import ChangePassword from "./ChangePassword";
import Reset from "authentication/Reset";
import MFA from "./MFA";

function HOCAuthenticator(ChildComponent) {
  return (props) => {
    /* eslint-disable */ // Eslint doesn't understand that this is a React component...
    const navigate = useNavigate();
    const redirectLinkRef = useRef<string>();
    const [isLoggedIn, setLoggedIn] = useState(false);

    function setRedirectLink(redirectLink: string) {
      redirectLinkRef.current = redirectLink;
    }

    function logInUser(value: boolean) {
      setLoggedIn(value);

      if (value && redirectLinkRef.current) {
        navigate(decodeURIComponent(redirectLinkRef.current));
      }
    }

    if (isLoggedIn) {
      return <ChildComponent {...props} />;
    } else {
      return (
        <Routes>
          <Route
            index
            element={
              <Login
                setLoggedIn={logInUser}
                setRedirectLink={setRedirectLink}
              />
            }
          />
          <Route path="reset" element={<Reset />} />
          <Route path="change" element={<ChangePassword />} />
          <Route path="mfa" element={<MFA setLoggedIn={logInUser} />} />
          <Route
            path="*"
            element={<CheckingCredentials setLoggedIn={logInUser} />}
          />
        </Routes>
      );
    }
  };
}

export { HOCAuthenticator };
