import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HOCAuthenticator } from "./authentication";
import { Viewer } from "./potree/potree";
import { Menu } from "./react/sidebar";
import { TargetLocation } from "potree/TargetLocation";
import ScreenshotPreview from "react/ScreenshotPreview";

const version = {
  major: 1,
  minor: 9,
  patch: 0,
};
console.log(
  "ARKION Lidar %d.%d.%d",
  version.major,
  version.minor,
  version.patch
);

const App: React.FC = () => {
  const [viewer, setViewer] = useState<Viewer | null>(null);

  useEffect(() => {
    window.name = "sq-lidar";

    const viewerInstance = new Viewer(
      document.getElementById("potree_render_area")
    );
    window.viewer = viewerInstance;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    viewerInstance.loadSettingsFromSearchParams(params);
    viewerInstance.setControls(viewerInstance.earthControls);
    viewerInstance.loadGlobalData();

    setViewer(viewerInstance);

    // Render the Menu component once viewer and sidebars are ready
    ReactDOM.render(
      <Menu start_open={!params?.scene} />,
      document.getElementById("sidebars")
    );

    if (params?.mapFilter) {
      viewerInstance.mapContext.query = decodeURIComponent(params.mapFilter);
    }

    let targetLocation: TargetLocation = { type: "NONE" };

    if (params?.image) {
      const imageId = parseInt(params.image, 10);
      targetLocation = {
        type: "IMAGE",
        imageId,
      };
    } else if (params?.lng && params?.lat) {
      targetLocation = {
        type: "COORD",
        lat: parseFloat(params.lat),
        lng: parseFloat(params.lng),
      };

      if (params?.alt) {
        targetLocation.alt = parseFloat(params.alt);
      }

      if (params?.pitch && params?.yaw) {
        targetLocation.pitch = parseFloat(params.pitch);
        targetLocation.yaw = parseFloat(params.yaw);
      }
    }

    viewerInstance.targetLocation = targetLocation;

    const cloud = window.location.pathname;
    const project = cloud.split("/")[1];

    if (cloud !== "/" && cloud !== "") {
      viewerInstance.loadProjectData(Number(project));
      if (params?.scene) {
        loadCloud(params.scene, viewerInstance);
      }
    }

    async function loadCloud(scene: string, viewer: Viewer) {
      await viewer.initializedPromise;
      viewer.loadScene(scene);
    }
  }, []);

  return (
    <>
      <ScreenshotPreview viewer={viewer} />
      <div id="potree_render_area">
        <div
          id="distance_class_wrapper"
          className="distance_class_panel_hidden"
        >
          <div id="distance_class_panel" />
        </div>
      </div>
      <div id="sidebars" />
      <ToastContainer newestOnTop theme="colored" />
    </>
  );
};

export default HOCAuthenticator(App);
