import * as ObjectRenderer from "potree/nova_renderer/objectRenderer";

/**
 * Regular renderer for the point cloud. Applies no special effects and purely renders points.
 */
export class PotreeRenderer {
  #viewer = null;

  constructor(viewer) {
    this.#viewer = viewer;
  }

  clear() {
    const viewer = this.#viewer;
    const gl = viewer.gl;

    // render skybox
    if (viewer.background === "skybox") {
      gl.clearColor(1.0, 0.0, 0.0, 1.0);
    } else if (viewer.background === "gradient") {
      gl.clearColor(0.0, 1.0, 0.0, 1.0);
    } else if (viewer.background === "black") {
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
    } else if (viewer.background === "white") {
      gl.clearColor(1.0, 1.0, 1.0, 1.0);
    } else {
      gl.clearColor(0.0, 0.0, 0.0, 0.0);
    }
  }

  render() {
    const viewer = this.#viewer;

    const camera = viewer.sceneContext.getActiveCamera();

    for (let pointcloud of this.#viewer.sceneContext.pointclouds) {
      const { material } = pointcloud;
      material.useEDL = false;
    }

    viewer.pRenderer.render(viewer.sceneContext.pointclouds, camera, null);

    // render scene
    ObjectRenderer.renderScene(
      viewer.sceneContext.scene,
      this.#viewer.gl,
      camera,
      this.#viewer.shaderCache
    );
  }
}
