import { Mesh } from "potree/geometry";
import { Box3, Euler, Sphere, Vector3 } from "potree/mathtypes";
import { Object3D } from "potree/object3d";
import {
  LineBasicMaterial,
  MeshBasicMaterial,
} from "potree/rendering/material";
import { getMousePointCloudIntersection } from "potree/utils/utils";
import { ToolBase } from "./base";
import { TRANSLATIONS } from "translation";
import { toast } from "react-toastify";
import { getAnnotations, postAnnotations } from "potree/skyqraft";
import { SQBoxBuffer, SQLineBuffer } from "potree/nova_renderer/buffer";
import { captureException } from "@sentry/react";

class BoxVolume extends Object3D {
  #clip = false;

  box: Mesh;
  frame: Mesh;

  boundingBox: Box3;
  boundingSphere: Sphere;

  constructor(
    boxGeometry: SQBoxBuffer,
    boxFrameGeometry: SQLineBuffer,
    material: MeshBasicMaterial,
    frameMaterial: LineBasicMaterial,
    args: {
      clip?: boolean;
    } = {}
  ) {
    super();
    this.#clip = args.clip || false;

    this.box = new Mesh(boxGeometry, material);
    this.boundingBox = this.box.geometry.boundingBox;
    this.add(this.box);

    this.frame = new Mesh(boxFrameGeometry, frameMaterial);
    this.add(this.frame);

    this.update();
  }

  update() {
    this.boundingBox = this.box.geometry.boundingBox;
    this.boundingSphere = this.boundingBox.getBoundingSphere(new Sphere());
  }

  raycast(raycaster, intersects) {
    let intersections = [];
    this.box.raycast(raycaster, intersections);

    if (intersections.length > 0) {
      let I = intersections[0];
      intersects.push({
        distance: I.distance,
        object: this,
        point: I.point.clone(),
      });
    }
  }

  get clip() {
    return this.#clip;
  }

  set clip(value) {
    if (this.#clip !== value) {
      this.#clip = value;

      this.update();
    }
  }
}

class BoundingBox extends BoxVolume {
  isBoundingBox = true;
  #volumeTool = null;

  // Box type is used by the PointCloudRenderer,
  // It determines the point coloration in a volume.
  boxType: number;

  /**
   * Did this box originat from the database or was it locally created?
   */
  fromDatabase = false;

  constructor(
    boxGeometry: SQBoxBuffer,
    boxFrameGeometry: SQLineBuffer,
    material: MeshBasicMaterial,
    frameMaterial: LineBasicMaterial,
    uuid = null,
    position: Vector3,
    scale: Vector3,
    rotation: Vector3,
    volumeTool: VolumeTool
  ) {
    super(boxGeometry, boxFrameGeometry, material, frameMaterial, {
      clip: true,
    });
    this.box.visible = false;

    this.#volumeTool = volumeTool;

    if (uuid !== null) {
      this.uuid = uuid;
    }

    this.position.copy(position);
    this.scale.copy(scale);

    const euler = new Euler(rotation.x, rotation.y, rotation.z, "XYZ");
    this.setRotationFromEuler(euler);
    this.updateMatrixWorld();

    this.addEventListener("dblclick", this.showTransformTool.bind(this));
  }

  get volumeTool() {
    return this.#volumeTool;
  }

  startDragging() {
    this.#volumeTool.viewer.inputHandler.startDragging(this);
    this.addEventListener("drag", this.drag);
    this.addEventListener("drop", this.drop);
  }

  drag = (e) => {
    const camera = this.#volumeTool.viewer.sceneContext.getActiveCamera();

    const intersection = getMousePointCloudIntersection(
      e.drag.end,
      this.#volumeTool.viewer.sceneContext.getActiveCamera(),
      this.#volumeTool.viewer,
      this.#volumeTool.viewer.sceneContext.pointclouds,
      { pickClipped: false }
    );

    if (intersection) {
      const wp = this.getWorldPosition(new Vector3()).applyMatrix4(
        camera.matrixWorldInverse
      );
      const w = Math.abs(wp.z / 5);

      this.scale.set(w, w, w);
      this.position.copy(intersection.location);
      this.updateMatrixWorld();
    }
  };

  drop = () => {
    this.removeEventListener("drag", this.drag);
    this.removeEventListener("drop", this.drop);
    this.#volumeTool.viewer.removeEventListener("cancel_insertions", this.drop);
    this.updateMatrixWorld();
  };

  showTransformTool() {
    this.#volumeTool.viewer.inputHandler.deselectAll();
    this.#volumeTool.viewer.inputHandler.toggleSelection(this);
  }

  updateFromDatabase(position, scale, rotation) {
    this.position.set(position);
    this.scale.set(scale);

    const euler = new Euler(rotation.x, rotation.y, rotation.z, "XYZ");
    this.setRotationFromEuler(euler);
    this.updateMatrixWorld();
  }
  serializeExtraData() {
    return {};
  }
}

export class AnnotationBox extends BoundingBox {
  isAnnotation = true;

  annotationType: number;

  constructor(
    boxGeometry: SQBoxBuffer,
    boxFrameGeometry: SQLineBuffer,
    material: MeshBasicMaterial,
    frameMaterial: LineBasicMaterial,
    uuid = null,
    position: Vector3,
    scale: Vector3,
    rotation: Vector3,
    volumeTool: VolumeTool,
    annotationType: number
  ) {
    super(
      boxGeometry,
      boxFrameGeometry,
      material,
      frameMaterial,
      uuid,
      position,
      scale,
      rotation,
      volumeTool
    );
    this.boxType = 9;

    this.annotationType = annotationType;
  }

  setAnnotationType(type: number) {
    this.annotationType = type;
    this.volumeTool.markDirty(this);
  }

  serializeExtraData() {
    return {
      // Snake case for API.
      annotation_type: Number(this.annotationType),
    };
  }
}

export class ClassifierBox extends BoundingBox {
  isClassifier = true;

  type_from?: number;
  #type_to: number;

  constructor(
    boxGeometry: SQBoxBuffer,
    boxFrameGeometry: SQLineBuffer,
    material: MeshBasicMaterial,
    frameMaterial: LineBasicMaterial,
    uuid = null,
    position: Vector3,
    scale: Vector3,
    rotation: Vector3,
    volumeTool: VolumeTool,
    type_from: number | null,
    type_to: number
  ) {
    super(
      boxGeometry,
      boxFrameGeometry,
      material,
      frameMaterial,
      uuid,
      position,
      scale,
      rotation,
      volumeTool
    );

    this.type_from = type_from;
    this.#type_to = type_to;
    this.boxType = this.#type_to;
  }

  get type_to(): number {
    return this.#type_to;
  }
  set type_to(value: number) {
    this.#type_to = value;
    this.boxType = value;
  }

  serializeExtraData() {
    return {
      type_from: this.type_from,
      type_to: this.#type_to,
    };
  }
}

export class VolumeTool extends ToolBase {
  #dirtyVolumes: Set<BoundingBox> = new Set();
  #removedVolumes = [];

  #annotationVolumes = [];
  #classificationVolumes = [];

  /**
   * Creating render objects once as they aren't changed between volumes.
   * We can reuse them for performance (memory & render property switching)!
   */
  boxGeometry: SQBoxBuffer = new SQBoxBuffer(1, 1, 1);
  boxFrameGeometry: SQLineBuffer;

  boxMaterial = new MeshBasicMaterial({
    color: 0x00ff00,
    transparent: true,
    opacity: 0.3,
    depthTest: true,
    depthWrite: false,
  });

  annotationBoxFrameMaterial = new LineBasicMaterial({ color: 0xffffff });
  classificationBoxFrameMaterial = new LineBasicMaterial({ color: 0x92b2c7 });

  constructor(viewer) {
    super(viewer, "scene_volume", true);

    this.viewer.inputHandler.addEventListener("delete", (e) => {
      let volumes = e.selection.filter((e) => e instanceof BoxVolume);
      volumes.forEach((e) => {
        this.viewer.sceneContext.removeVolume(e);
        this.scene.remove(e);
      });
    });

    viewer.addEventListener(
      "scene_context_changed",
      this.onSceneContextChange.bind(this)
    );

    viewer.sceneContext.addEventListener(
      "volume_removed",
      this.onRemove.bind(this)
    );

    this.boxGeometry.computeBoundingBox();

    this.boxFrameGeometry = new SQLineBuffer([
      // bottom
      new Vector3(-0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, -0.5, 0.5),
      // top
      new Vector3(-0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
      new Vector3(-0.5, 0.5, 0.5),
      // sides
      new Vector3(-0.5, -0.5, 0.5),
      new Vector3(-0.5, 0.5, 0.5),
      new Vector3(0.5, -0.5, 0.5),
      new Vector3(0.5, 0.5, 0.5),
      new Vector3(0.5, -0.5, -0.5),
      new Vector3(0.5, 0.5, -0.5),
      new Vector3(-0.5, -0.5, -0.5),
      new Vector3(-0.5, 0.5, -0.5),
    ]);
  }

  markDirty(volume) {
    this.#dirtyVolumes.add(volume);
  }

  onRemove(e) {
    this.scene.remove(e.volume);

    if (e.volume.fromDatabase)
      // Only save removal of volumes from the DB.
      this.#removedVolumes.push(e.volume.uuid);

    this.#dirtyVolumes.delete(e.volume);
  }
  loadFromDatabase(e) {
    const scene = this.viewer.getScene();

    if (!window.confirm(TRANSLATIONS.EN.VOLUMES.ConfirmLoad)) {
      return;
    }

    getAnnotations(scene, this.viewer.sceneContext.signal)
      .then((response) => {
        //@ts-ignore Response is not typed yet.
        for (const annotation of response.annotations) {
          const position = new Vector3(
            annotation.position_x,
            annotation.position_y,
            annotation.position_z
          );
          const scale = new Vector3(
            annotation.scale_x,
            annotation.scale_y,
            annotation.scale_z
          );
          const rotation = new Vector3(
            annotation.rotation_x,
            annotation.rotation_y,
            annotation.rotation_z
          );

          const existingBox = this.#annotationVolumes.find(
            (volume) => volume.uuid === annotation.uuid
          );
          if (!!existingBox) {
            // Update existing.
            existingBox.updateFromDatabase(position, scale, rotation);
          } else {
            // Create annotation.
            const volume = new AnnotationBox(
              this.boxGeometry,
              this.boxFrameGeometry,
              this.boxMaterial,
              this.annotationBoxFrameMaterial,
              annotation.id,
              position,
              scale,
              rotation,
              this,
              annotation.annotationType
            );
            volume.fromDatabase = true;
            this.#annotationVolumes.push(volume);

            this.viewer.sceneContext.addVolume(volume);
            this.scene.add(volume);
          }
        }

        //@ts-ignore Response is not typed yet.
        for (const classifier of response.classifiers) {
          const position = new Vector3(
            classifier.position_x,
            classifier.position_y,
            classifier.position_z
          );
          const scale = new Vector3(
            classifier.scale_x,
            classifier.scale_y,
            classifier.scale_z
          );
          const rotation = new Vector3(
            classifier.rotation_x,
            classifier.rotation_y,
            classifier.rotation_z
          );

          // TODO: OMMT 2: O(new * existing) linear search is bad for performance.
          const existingBox = this.#classificationVolumes.find(
            (volume) => volume.uuid === classifier.id
          );
          if (!!existingBox) {
            existingBox.updateFromDatabase(position, scale, rotation);
          } else {
            const volume = new ClassifierBox(
              this.boxGeometry,
              this.boxFrameGeometry,
              this.boxMaterial,
              this.classificationBoxFrameMaterial,
              classifier.id,
              position,
              scale,
              rotation,
              this,
              classifier.type_from,
              classifier.type_to
            );
            volume.fromDatabase = true;
            this.#classificationVolumes.push(volume);

            this.viewer.sceneContext.addVolume(volume);
            this.scene.add(volume);
          }
        }
      })
      .catch((err) => {
        const exceptionHint = {
          event_id: "volumeTool.loadFromDatabase",
          originalException: err,
          data: {
            projectID: this.viewer.getProject(),
          },
        };

        captureException(err, exceptionHint);
        console.error(err);
      });
  }
  saveToDatabase() {
    if (this.viewer.isDemoMode()) {
      window.alert(TRANSLATIONS.EN.VOLUMES.DemoSaveWarning);

      toast.success(TRANSLATIONS.EN.VOLUMES.DemoSaveResponse);

      return;
    }

    if (this.#dirtyVolumes.size === 0 && this.#removedVolumes.length === 0) {
      window.alert(TRANSLATIONS.EN.VOLUMES.SaveNoChanges);
      return;
    }

    const annotation_map = [];
    const classifier_map = [];
    for (const volume of this.#dirtyVolumes.values()) {
      let pos = this.viewer.convertLidarToWSGS84(
        volume.position.x,
        volume.position.y,
        volume.position.z
      );

      let serialized_volume = {
        uuid: volume.uuid,
        lat: pos[0],
        lng: pos[1],
        position: { ...volume.position },
        scale: { ...volume.scale },
        rotation: {
          x: volume.rotation._x,
          y: volume.rotation._y,
          z: volume.rotation._z,
        },
        ...volume.serializeExtraData(),
      };

      if (volume instanceof AnnotationBox) {
        annotation_map.push(serialized_volume);
      } else if (volume instanceof ClassifierBox) {
        classifier_map.push(serialized_volume);
      } else {
        console.warn("Unhandled box type.");
      }
    }

    const scene = this.viewer.getScene();

    let confirmMessage = TRANSLATIONS.EN.VOLUMES.ConfirmSave;
    const updatedVolumesCount = annotation_map.length + classifier_map.length;
    if (updatedVolumesCount > 0)
      confirmMessage += `\n${updatedVolumesCount} volume(s) will be updated of ${this.viewer.sceneContext.volumes.length} in scene`;
    if (this.#removedVolumes.length > 0)
      confirmMessage += `\n${this.#removedVolumes.length} volume(s) will be removed.`;

    if (!window.confirm(confirmMessage)) {
      return;
    }

    postAnnotations(scene, this.#removedVolumes, annotation_map, classifier_map)
      .then((_) => {
        toast.success(TRANSLATIONS.EN.VOLUMES.SaveSuccess);

        for (const box of this.#dirtyVolumes) {
          box.fromDatabase = true;
        }

        this.#dirtyVolumes.clear();
        this.#removedVolumes.length = 0;
      })
      .catch((err) => {
        toast.error(`${TRANSLATIONS.EN.VOLUMES.SaveFailure} <br>'${err}'`);
      });
  }

  onSceneContextChange(e) {
    e.sceneContext.addEventListener("volume_removed", this.onRemove.bind(this));

    this.#dirtyVolumes.clear();
    this.#removedVolumes.length = 0;

    this.#annotationVolumes.length = 0;
    this.#classificationVolumes.length = 0;
  }

  insertAnnotation() {
    let annotation = new AnnotationBox(
      this.boxGeometry,
      this.boxFrameGeometry,
      this.boxMaterial,
      this.annotationBoxFrameMaterial,
      null,
      new Vector3(),
      new Vector3(1, 1, 1),
      new Vector3(),
      this,
      null
    );
    this.#dirtyVolumes.add(annotation);

    this.#annotationVolumes.push(annotation);

    this.viewer.sceneContext.addVolume(annotation);
    this.scene.add(annotation);

    annotation.startDragging();
    annotation.showTransformTool();
  }

  insertClassifier(type) {
    let classifier = new ClassifierBox(
      this.boxGeometry,
      this.boxFrameGeometry,
      this.boxMaterial,
      this.classificationBoxFrameMaterial,
      null,
      new Vector3(),
      new Vector3(1, 1, 1),
      new Vector3(),
      this,
      null,
      type
    );
    this.#dirtyVolumes.add(classifier);

    this.viewer.sceneContext.addVolume(classifier);
    this.#classificationVolumes.push(classifier);
    this.scene.add(classifier);

    classifier.startDragging();
    classifier.showTransformTool();
  }
}
