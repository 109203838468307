// @ts-check

import { Mesh, Sprite } from "potree/geometry";
import { Object3D, Scene } from "potree/object3d";
import { Camera } from "potree/rendering/camera";
import { Material } from "potree/rendering/material";
import { SQCache } from "./cache";
import { SQBuffer } from "./buffer";
import { BackSide } from "potree/rendering/constants";

/**
 * Renders all renderable objects in the Scene from the viewpoint of the provided camera.
 *
 * This function assumes that the Camera's & Scene's MatrixWorld is up to date.
 *
 */
export function renderScene(
  scene: Scene,
  gl: WebGL2RenderingContext,
  camera: Camera,
  cache: SQCache
) {
  if (!scene.visible) {
    return;
  }

  // Id of the currently bound material.
  let currentMaterialId = -1;
  // Type of the currently bound material.
  let currentMaterialType = null;
  // Cached uniform names to locations from the currently bound shader.
  let currentUniforms = null;

  // Sorting material id outside of the function so it's not repeated for all child objects which are added.
  const renderableObjects = filterRenderableObjects(scene.children).sort(
    (a, b) => a.material.id - b.material.id
  );

  if (renderableObjects.length == 0) {
    return;
  }

  // Render objects
  for (const renderable of renderableObjects) {
    const material = renderable.material;

    // Material ids are unique per material instance.
    // Objects reusing the same instance will not trigger.
    if (currentMaterialId !== material.id) {
      // Switch shader if the material type changed.
      if (currentMaterialType != material.type) {
        // Get shader from cache (this will compile a new one if necessary).
        const { program, uniforms } = cache.getProgramNamed(
          gl,
          material.type,
          material.vertShader,
          material.fragShader
        );
        currentUniforms = uniforms;

        // Tell WebGL to switch to the new shader.
        gl.useProgram(program);

        // All uniforms have been reset because we switched shader.
        // UNIFORMS are additonal inputs to the shader beyond geometry data.

        // Set camera projection matrix.
        gl.uniformMatrix4fv(
          currentUniforms.get("projectionMatrix"),
          false,
          camera.projectionMatrix.elements
        );

        currentMaterialType = material.type;
      }

      // Update material specific shader uniforms.
      material.updateUniforms(gl, currentUniforms);

      // Enable or disable writing to the depth buffer.
      // This is in simplest terms a texture that tells us how far away from the screen an object is on the depth axis.
      // Certain effects such as Eye-Dome Lightning will read the depth buffer to create effects using knowledge of where other geometry exists.
      gl.depthMask(material.depthWrite);
      gl.depthFunc(gl.LEQUAL);

      // Set depthTest.
      // This uses the depth buffer to avoid rendering objects that are hidden by other objects.
      if (material.depthTest) {
        gl.enable(gl.DEPTH_TEST);
      } else {
        gl.disable(gl.DEPTH_TEST);
      }

      // Set color blending.
      // This makes it so when we write color to the screen it is combined with existing colors (based on their alpha).
      // If this is disabled we simply overwrite the previous value.
      if (material.transparent) {
        gl.enable(gl.BLEND);
        // Set the blend function to use.
        // This defines the math for blending colors.
        // I barely understand it, please read the MDN explanation: https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/blendFunc#constants
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        gl.blendFuncSeparate(
          gl.SRC_ALPHA, // Source factor for RGB
          gl.ONE_MINUS_SRC_ALPHA, // Destination factor for RGB
          gl.ONE, // Source factor for Alpha
          gl.ONE_MINUS_SRC_ALPHA // Destination factor for Alpha
        );
        gl.blendEquation(gl.FUNC_ADD); // BLEND_EQUATION_RGB: FUNC_ADD
        gl.blendEquationSeparate(gl.FUNC_ADD, gl.FUNC_ADD); // BLEND_EQUATION_ALPHA: FUNC_ADD
      } else {
        gl.disable(gl.BLEND);
      }

      // Set which side of a triangle should be culled.
      // For most materials we cull the back side.
      if (material.side == BackSide) {
        // Note: Rendering the BackSide means culling the front.
        gl.cullFace(gl.FRONT);
      } else {
        gl.cullFace(gl.BACK);
      }

      // Save which material we currently use.
      // There's no need for us to set uniforms again within the same material.
      currentMaterialId = material.id;
    }

    // Set the renderable's matrix i.e it's position, rotation, and scale in the shader.
    // This has to be done for every object since they'll all have different matrices.
    // WE HAVE TO DO THIS ON THE CPU-SIDE BECAUSE OF FLOATING POINT PRECISION.
    // Point clouds are soo far from 0.0 that all 32bit math breaks down if we try to do it in world space so we have to do this math here where it is 64 bit.
    renderable.object.modelViewMatrix.multiplyMatrices(
      camera.matrixWorldInverse,
      renderable.object.matrixWorld
    );
    gl.uniformMatrix4fv(
      currentUniforms.get("modelViewMatrix"),
      false,
      renderable.object.modelViewMatrix.elements
    );
    // We also need to send the model matrix for normals. This is fine because normals only care about rotation.
    gl.uniformMatrix4fv(
      currentUniforms.get("modelMatrix"),
      false,
      renderable.object.matrixWorld.elements
    );

    // Create & upload geometry to GPU if it hasn't already been created.
    const geometry = renderable.geometry;
    // Initialize & upload geometry to the GPU if it hasn't already been.
    // This will create GPU side arrays & define what attribute/in they map to in the shader.
    if (renderable.geometry.vao === null) {
      renderable.geometry.initializeVertexArrayObject(gl);
    } else if (renderable.geometry.needsBufferUpdate) {
      renderable.geometry.updateBuffers(gl);
    }

    // Bind vertex array object.
    // This tells the GPU all the attributes our geometry has which it needs to pass to the shader.
    gl.bindVertexArray(geometry.vao);

    if (geometry.hasIndices) {
      // Fetch indices count.
      const indices_count = geometry.indices.length;

      // drawElements = Draw Geometry with indices.
      // Render mode defines if we are drawing triangles or lines.
      // Indices count tells the GPU the amount of triangles we have in the geometry.
      // gl.UNSIGNED_SHORT is the type of the indices. We use UNSIGNED_SHORT because it's small but allows us to have more than 255 indices.
      // It's probably not worth putting it lower.
      // Final parameter is offset in the buffer, we aren't combining any of our geometry in the same buffer so it will always be zero.
      gl.drawElements(material.renderMode, indices_count, gl.UNSIGNED_SHORT, 0);
    } else {
      // 0 here represent the offset into the buffer, again, no combining any geometry.
      // Final parameter instead represents the number of points we want to draw.
      // Since we are 3D, this will always be 1/3 of the vertex count (because each vertex has 3 axes & is stored in a flat array).
      // @ts-ignore
      gl.drawArrays(material.renderMode, 0, geometry.vertices.length / 3);
    }
  }

  // Clean up unbind VAO.
  // Removes all our properties for the next render.
  // Since we rebind it for every Renderable we only need to unbind it at the end.
  gl.bindVertexArray(null);
}

/**
 * Returns objects to render, it's material, and geometry.
 * Filters out hidden objects & non-renderables.
 *
 */
function filterRenderableObjects(
  objects: Object3D[]
): { object: Mesh | Sprite; material: Material; geometry: SQBuffer }[] {
  const result = [];

  for (const object of objects) {
    if (object.visible) {
      if (object instanceof Mesh) {
        const material = object.material;
        const geometry = object.geometry;
        if (material?.visible) {
          result.push({
            object,
            material,
            geometry,
          });
        }
      } else if (object instanceof Sprite) {
        const material = object.material;
        const geometry = object.geometry;
        if (material?.visible) {
          result.push({
            object,
            material,
            geometry,
          });
        }
      }

      result.push(...filterRenderableObjects(object.children));
    }
  }

  // @ts-ignore
  return result;
}
