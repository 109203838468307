import { Close, Done } from "@mui/icons-material";
import { captureException } from "@sentry/react";
import axios, { AxiosResponse } from "axios";
import { BASE_URI } from "config";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getMapURL } from "utils/apiDetails";
import { Circle, Overlay } from "./styles";

interface Metadata {
  lat: number;
  lng: number;
  alt: number;
  pitch: number;
  compass_dir: number;
}

interface ImageData {
  url: string | null;
  blob: Blob | null;
  metadata: Metadata | null;
}
const ScreenshotPreview = ({ viewer }) => {
  const [imageData, setImageData] = useState<ImageData | null>(null);

  const handleViewerEvent = ({
    url,
    blob,
    metadata,
  }: {
    url: string;
    blob: Blob;
    metadata: Metadata;
  }) => {
    setImageData({ url, blob, metadata });
  };

  const uploadScreenshot = () => {
    if (window.confirm("Are you sure you want to upload this screenshot?")) {
      console.log("Image confirmed:", imageData);

      const formData = new FormData();
      formData.append("file", imageData.blob, "screenshot.jpeg"); // Append Blob as a file
      formData.append("metadata", JSON.stringify(imageData.metadata));

      const projectId = viewer.getProject();
      const scene = viewer.getScene();

      axios
        .post(`${BASE_URI}/potree/screenshot/${projectId}/${scene}`, formData, {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch((error) => {
          const exceptionHint = {
            event_id: "lidar.react.ScreenshotPreview.uploadScreenshot",
            originalException: error,
          };

          captureException(error, exceptionHint);

          toast.error("Failed to upload screenshot.");
        })
        .then((response: AxiosResponse<{ imageId: number }, any>) => {
          const imageId = response.data.imageId;
          const projectId = viewer.getProject();

          // OPEN IMAGE!
          viewer.openImageInMap(projectId, imageId);

          setImageData(null);
        });
    }
  };

  useEffect(() => {
    if (viewer) {
      viewer.screenshotTool.addEventListener(
        "screenshotCaptured",
        handleViewerEvent
      );

      // Need to remove the event listener when this dismounts.
      return () => {
        viewer.screenshotTool.removeEventListener(
          "screenshotCaptured",
          handleViewerEvent
        );
      };
    }
  }, [viewer]);

  if (!imageData) return null;

  return (
    <Overlay>
      <ImageContainer>
        <h2> Upload this screenshot? </h2>
        <ScreenshotImage src={imageData.url} alt="Captured Screenshot" />
        <ActionContainer>
          <Circle color="red" onClick={() => setImageData(null)}>
            <Close sx={{ color: "#ffff" }} />
          </Circle>
          <Circle color="lightGreen" onClick={uploadScreenshot}>
            <Done sx={{ color: "#ffff" }} />
          </Circle>
        </ActionContainer>
      </ImageContainer>
    </Overlay>
  );
};

// Inline styles for simplicity
const ImageContainer = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-height: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

const ScreenshotImage = styled.img`
    max-width: 100%;
    height: 70vh;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    object-fit: contain;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 10px;
`;

export default ScreenshotPreview;
