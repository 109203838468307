//@ts-check

import { SQBoxBuffer, SQPlaneBuffer } from "potree/nova_renderer/buffer";
import { Mesh } from "../geometry";
import { Box3, Matrix4, Sphere, Vector3, Vector4 } from "../mathtypes";
import { Scene } from "../object3d";
import {
  Camera,
  OrthographicCamera,
  PerspectiveCamera,
} from "../rendering/camera";
import { NearestFilter, RGBAFormat, RGBFormat } from "../rendering/constants";
import { DataTexture } from "../rendering/datatexture";
import { Material, SkyboxMaterial } from "../rendering/material";
import TWEEN from "tween.js";
import * as ObjectRenderer from "../nova_renderer/objectRenderer";
import { Ray, Raycaster } from "potree/raycasting";

/**
 * adapted from mhluska at https://github.com/mrdoob/three.js/issues/1561
 */
export function computeTransformedBoundingBox(box: Box3, transform: Matrix4) {
  let vertices = [
    new Vector3(box.min.x, box.min.y, box.min.z).applyMatrix4(transform),
    new Vector3(box.min.x, box.min.y, box.min.z).applyMatrix4(transform),
    new Vector3(box.max.x, box.min.y, box.min.z).applyMatrix4(transform),
    new Vector3(box.min.x, box.max.y, box.min.z).applyMatrix4(transform),
    new Vector3(box.min.x, box.min.y, box.max.z).applyMatrix4(transform),
    new Vector3(box.min.x, box.max.y, box.max.z).applyMatrix4(transform),
    new Vector3(box.max.x, box.max.y, box.min.z).applyMatrix4(transform),
    new Vector3(box.max.x, box.min.y, box.max.z).applyMatrix4(transform),
    new Vector3(box.max.x, box.max.y, box.max.z).applyMatrix4(transform),
  ];

  let boundingBox = new Box3();
  boundingBox.setFromPoints(vertices);

  return boundingBox;
}

/**
 * Add separators to large numbers
 */
export function addCommas(nStr: string): string {
  nStr += "";
  let x = nStr.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  return x1 + x2;
}

// Scene is actually SceneContext but not typescripted.
export function moveTo(scene: any, endPosition: Vector3, endTarget: Vector3) {
  let view = scene.view;
  let camera = scene.getActiveCamera();
  let animationDuration = 500;
  let easing = TWEEN.Easing.Quartic.Out;

  {
    // animate camera position
    let tween = new TWEEN.Tween(view.position).to(
      endPosition,
      animationDuration
    );
    tween.easing(easing);
    tween.start();
  }

  {
    // animate camera target
    let camTargetDistance = camera.position.distanceTo(endTarget);
    let target = new Vector3().addVectors(
      camera.position,
      camera
        .getWorldDirection(new Vector3())
        .clone()
        .multiplyScalar(camTargetDistance)
    );
    let tween = new TWEEN.Tween(target).to(endTarget, animationDuration);
    tween.easing(easing);
    tween.onUpdate(() => {
      view.lookAt(target);
    });
    tween.onComplete(() => {
      view.lookAt(target);
    });
    tween.start();
  }
}

export function loadSkybox(path: string): Scene {
  let scene = new Scene();

  const format = ".jpg";
  const urls = [
    path + "px" + format,
    path + "nx" + format,
    path + "py" + format,
    path + "ny" + format,
    path + "pz" + format,
    path + "nz" + format,
  ];

  const skyboxMaterial = new SkyboxMaterial(urls);

  const skyGeometry = new SQBoxBuffer(1.0, 1.0, 1.0);
  const skybox = new Mesh(skyGeometry, skyboxMaterial);

  scene.add(skybox);

  return scene;
}

export function createBackgroundTexture(width: number, height: number) {
  function gauss(x: number, y: number) {
    return (1 / (2 * Math.PI)) * Math.exp(-(x * x + y * y) / 2);
  }

  const size = width * height;
  let data = new Uint8Array(3 * size);

  const chroma = [1, 1.5, 1.7];
  const max = gauss(0, 0);

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      let u = 2 * (x / width) - 1;
      let v = 2 * (y / height) - 1;

      let i = x + width * y;
      let d = gauss(2 * u, 2 * v) / max;
      let r = (Math.random() + Math.random() + Math.random()) / 3;
      r = (d * 0.5 + 0.5) * r * 0.03;
      r = r * 0.4;

      data[3 * i + 0] = 255 * (d / 15 + 0.05 + r) * chroma[0];
      data[3 * i + 1] = 255 * (d / 15 + 0.05 + r) * chroma[1];
      data[3 * i + 2] = 255 * (d / 15 + 0.05 + r) * chroma[2];
    }
  }

  let texture = new DataTexture(data, width, height, RGBFormat);
  texture.needsUpdate = true;

  return texture;
}

export function zoomToLocation(
  mouse,
  controls,
  pickClipped: boolean,
  moveSpeedDivider = 1
) {
  const camera = controls.sceneContext.getActiveCamera();
  const view = controls.sceneContext.view;

  const I = getMousePointCloudIntersection(
    mouse,
    camera,
    controls.viewer,
    controls.sceneContext.pointclouds,
    { pickClipped: pickClipped }
  );

  if (I === null) {
    return;
  }

  let targetRadius = 0;
  {
    const minimumJumpDistance = 0.2;

    const domElement = controls.viewer.renderContext.canvas;
    const ray = mouseToRay(
      mouse,
      camera,
      domElement.clientWidth,
      domElement.clientHeight
    );

    const nodes = I.pointcloud.nodesOnRay(I.pointcloud.visibleNodes, ray);
    let lastNode = nodes[nodes.length - 1];
    const radius = lastNode.getBoundingSphere(new Sphere()).radius;
    targetRadius = Math.min(controls.sceneContext.view.radius, radius);
    targetRadius = Math.max(minimumJumpDistance, targetRadius);
  }

  const d = view.direction.multiplyScalar(-1).multiplyScalar(targetRadius);
  const cameraTargetPosition = new Vector3().addVectors(I.location, d);
  const animationDuration = 600;
  const easing = TWEEN.Easing.Quartic.Out;

  {
    // animate
    let value = { x: 0 };
    let tween = new TWEEN.Tween(value).to({ x: 1 }, animationDuration);
    tween.easing(easing);
    controls.tweens.push(tween);

    const startPos = controls.sceneContext.view.position.clone();
    const targetPos = cameraTargetPosition.clone();
    const startRadius = controls.sceneContext.view.radius;
    const targetRadius = cameraTargetPosition.distanceTo(I.location);

    tween.onUpdate(() => {
      const t = value.x;
      view.position.x = (1 - t) * startPos.x + t * targetPos.x;
      view.position.y = (1 - t) * startPos.y + t * targetPos.y;
      view.position.z = (1 - t) * startPos.z + t * targetPos.z;

      view.radius = (1 - t) * startRadius + t * targetRadius;
      controls.viewer.setMoveSpeed(view.radius / moveSpeedDivider);
    });

    tween.onComplete(() => {
      controls.tweens = controls.tweens.filter((e) => e !== tween);
      view.dirty();
    });

    tween.start();
  }
}

export function getMousePointCloudIntersection(
  mouse: { x: number; y: number },
  camera: Camera,
  viewer,
  pointclouds: any[],
  params: {
    pickClipped?: boolean;
  } = {}
) {
  const renderContext = viewer.renderContext;

  const nmouse = {
    x: (mouse.x / renderContext.canvas.clientWidth) * 2 - 1,
    y: -(mouse.y / renderContext.canvas.clientHeight) * 2 + 1,
  };

  let pickParams: { pickClipped?: boolean; x: number; y: number } = {
    x: mouse.x,
    y: renderContext.canvas.clientHeight - mouse.y,
  };

  if (params.pickClipped) {
    pickParams.pickClipped = params.pickClipped;
  }

  let raycaster = new Raycaster();
  camera.setRaycaster(raycaster, nmouse);
  let ray = raycaster.ray;

  let selectedPointcloud = null;
  let closestDistance = Infinity;
  let closestIntersection = null;
  let closestPoint = null;

  for (const pointcloud of pointclouds) {
    const point = pointcloud.pick(viewer, camera, ray, pickParams);

    if (!point) {
      continue;
    }

    const distance = camera.position.distanceTo(point.position);

    if (distance < closestDistance) {
      closestDistance = distance;
      selectedPointcloud = pointcloud;
      closestIntersection = point.position;
      closestPoint = point;
    }
  }

  if (selectedPointcloud) {
    return {
      location: closestIntersection,
      distance: closestDistance,
      pointcloud: selectedPointcloud,
      point: closestPoint,
    };
  } else {
    return null;
  }
}

export function mouseToRay(mouse, camera, width, height) {
  let normalizedMouse = {
    x: (mouse.x / width) * 2 - 1,
    y: -(mouse.y / height) * 2 + 1,
  };

  let vector = new Vector3(normalizedMouse.x, normalizedMouse.y, 0.5);
  let origin = camera.position.clone();
  camera.unprojectVector3(vector);
  let direction = new Vector3().subVectors(vector, origin).normalize();

  let ray = new Ray(origin, direction);

  return ray;
}

/**
 * @param {number} radius
 * @param {Camera} camera
 * @param {number} distance
 * @param {number} screenWidth
 * @param {number} screenHeight
 */
export function projectedRadius(
  radius: number,
  camera: Camera,
  distance: number,
  screenWidth: number,
  screenHeight: number
): number {
  if (camera instanceof OrthographicCamera) {
    return projectedRadiusOrtho(
      radius,
      camera.projectionMatrix,
      screenWidth,
      screenHeight
    );
  } else if (camera instanceof PerspectiveCamera) {
    return projectedRadiusPerspective(
      radius,
      (camera.fov * Math.PI) / 180,
      distance,
      screenHeight
    );
  } else {
    throw new Error("Invalid camera, can't project radius.");
  }
}

/**
 * @param {number} radius
 * @param {number} fov
 * @param {number} distance
 * @param {number} screenHeight
 */
export function projectedRadiusPerspective(
  radius: number,
  fov: number,
  distance: number,
  screenHeight: number
): number {
  let projFactor = 1 / Math.tan(fov / 2) / distance;
  projFactor = (projFactor * screenHeight) / 2;

  return radius * projFactor;
}

export function projectedRadiusOrtho(
  radius: number,
  proj: Matrix4,
  screenWidth: number,
  screenHeight: number
): number {
  let p1 = new Vector4(0);
  let p2 = new Vector4(radius);

  p1.applyMatrix4(proj);
  p2.applyMatrix4(proj);

  const projectedP1 = new Vector3(p1.x, p1.y, p1.z);
  const projectedP2 = new Vector3(p2.x, p2.y, p2.z);
  projectedP1.x = (projectedP1.x + 1.0) * 0.5 * screenWidth;
  projectedP1.y = (projectedP1.y + 1.0) * 0.5 * screenHeight;
  projectedP2.x = (projectedP2.x + 1.0) * 0.5 * screenWidth;
  projectedP2.y = (projectedP2.y + 1.0) * 0.5 * screenHeight;
  return projectedP1.distanceTo(projectedP2);
}

// code taken from three.js
// ImageUtils - generateDataTexture()
/**
 * @param {number} width
 * @param {number} height
 * @param {import("../rendering/types").Color} color
 */
export function generateDataTexture(width, height, color) {
  const size = width * height;
  let data = new Uint8Array(4 * width * height);

  const r = Math.floor(color.r * 255);
  const g = Math.floor(color.g * 255);
  const b = Math.floor(color.b * 255);

  for (let i = 0; i < size; i++) {
    data[i * 3] = r;
    data[i * 3 + 1] = g;
    data[i * 3 + 2] = b;
  }

  let texture = new DataTexture(data, width, height, RGBAFormat);
  texture.needsUpdate = true;
  texture.magFilter = NearestFilter;

  return texture;
}

/**
 * @param {Box3} aabb
 * @param {number} index
 */
export function createChildAABB(aabb, index) {
  let min = aabb.min.clone();
  let max = aabb.max.clone();
  let size = new Vector3().subVectors(max, min);

  if ((index & 0b0001) > 0) {
    min.z += size.z / 2;
  } else {
    max.z -= size.z / 2;
  }

  if ((index & 0b0010) > 0) {
    min.y += size.y / 2;
  } else {
    max.y -= size.y / 2;
  }

  if ((index & 0b0100) > 0) {
    min.x += size.x / 2;
  } else {
    max.x -= size.x / 2;
  }

  return new Box3(min, max);
}

export const screenPass = new (function () {
  this.screenScene = new Scene();
  this.screenQuad = new Mesh(new SQPlaneBuffer(2, 2), null);
  this.screenScene.add(this.screenQuad);
  this.screenScene.updateMatrixWorld();
  this.camera = new Camera();
  this.camera.updateMatrixWorld();

  this.render = function (
    /** @type {WebGL2RenderingContext} */ gl,
    /** @type {Material} */ material,
    /** @type {import("../nova_renderer/cache").SQCache} */ cache
  ) {
    this.screenQuad.material = material;

    ObjectRenderer.renderScene(this.screenScene, gl, this.camera, cache);
  };
})();
