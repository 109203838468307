import { Vector3 } from "potree/mathtypes";
import { EyeDomeLightingMaterial } from "potree/rendering/material";
import * as Utils from "potree/utils/utils";
import * as ObjectRenderer from "potree/nova_renderer/objectRenderer";

/**
 * Renderer that applies "eye-dome lighting" to points creating a greater feel of depth.
 */
export class EDLRenderer {
  #edlMaterial: EyeDomeLightingMaterial = null;

  #frameBuffer: WebGLFramebuffer;
  #renderTexture: WebGLTexture;
  #renderDepthBuffer: WebGLRenderbuffer;

  #viewer;

  constructor(viewer) {
    this.#viewer = viewer;
  }

  /**
   *  Create a frame buffer for rendering the point cloud to.
   *  This is essentially second canvas we can render to instead of the regular canvas.
   *  For EDL it is used to draw the points & then draw a texture of the points with additional visual effects.
   */
  #createFrameBuffer(width: number, height: number) {
    const gl: WebGL2RenderingContext = this.#viewer.gl;

    this.#frameBuffer = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.#frameBuffer);

    this.#renderTexture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, this.#renderTexture);

    // Activate extension so we can have float color.
    gl.getExtension("EXT_color_buffer_float");
    // Initialize the texture with null data. This data will be filled in on the GPU when we render to it.
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA32F,
      width,
      height,
      0,
      gl.RGBA,
      gl.FLOAT,
      null
    );

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      this.#renderTexture,
      0
    );

    /**
     * Create a render buffer for depth.
     *  The difference between a buffer & texture is that we can't sample the buffer.
     *  But we don't need to sample depth, we only use it to make sure we render geometry in the correct order.
     */
    this.#renderDepthBuffer = gl.createRenderbuffer();
    gl.bindRenderbuffer(gl.RENDERBUFFER, this.#renderDepthBuffer);
    /**
     * Create a 24bit depth buffer.
     *
     * Depth is stored in the range of 0.0 to 1.0 (as a distance from the camera between the near & far clip planes, anything outside these clip planes are not rendered).
     * Our current near & far clips are 0.1 & 1 000 000 respectively (see SceneContext::cameraP).
     */
    gl.renderbufferStorage(
      gl.RENDERBUFFER,
      gl.DEPTH_COMPONENT24,
      width,
      height
    );

    // Attach the renderbuffer to the framebuffer's attachment.
    gl.framebufferRenderbuffer(
      gl.FRAMEBUFFER,
      gl.DEPTH_ATTACHMENT,
      gl.RENDERBUFFER,
      this.#renderDepthBuffer
    );

    // Check if the frame buffer has been set up correctly.
    const status = gl.checkFramebufferStatus(gl.FRAMEBUFFER);
    if (status !== gl.FRAMEBUFFER_COMPLETE) {
      console.error("Framebuffer is not complete!", status);
      switch (status) {
        case gl.FRAMEBUFFER_INCOMPLETE_ATTACHMENT:
          console.error("Incomplete framebuffer attachment.");
          break;
        case gl.FRAMEBUFFER_INCOMPLETE_MISSING_ATTACHMENT:
          console.error("Framebuffer missing attachment.");
          break;
        case gl.FRAMEBUFFER_UNSUPPORTED:
          console.error("Framebuffer format not supported.");
          break;
        default:
          console.error("Framebuffer error, status: " + status);
          break;
      }
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  #initEDL() {
    if (this.#edlMaterial != null) {
      return;
    }

    this.#edlMaterial = new EyeDomeLightingMaterial();

    const { width, height } = this.#viewer.renderContext.size;
    this.#createFrameBuffer(width, height);

    this.#viewer.renderContext.addEventListener(
      "render_size_changed",
      this.#onRenderSizeChanged.bind(this)
    );
  }

  /**
   *  Resize the framebuffer's renderTexture if the dimensions have changed.
   *
   */
  #onRenderSizeChanged(e) {
    const { width, height } = e;
    const gl = this.#viewer.gl;

    gl.bindTexture(gl.TEXTURE_2D, this.#renderTexture);
    // Just like when we create it, we fill it with null data.
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA32F,
      width,
      height,
      0,
      gl.RGBA,
      gl.FLOAT,
      null
    );

    gl.bindRenderbuffer(gl.RENDERBUFFER, this.#renderDepthBuffer);
    gl.renderbufferStorage(
      gl.RENDERBUFFER,
      gl.DEPTH_COMPONENT16,
      width,
      height
    );
  }

  #clearFramebuffer() {
    const viewer = this.#viewer;
    const { gl } = viewer;

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.#frameBuffer);
    gl.depthMask(true); // Ensure depth writing is enabled else it won't be cleared it.
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  clear() {
    this.#initEDL();
    const { gl, background } = this.#viewer;

    if (background === "skybox") {
      gl.clearColor(0.0, 0.0, 0.0, 0.0);
    } else if (background === "gradient") {
      gl.clearColor(0.0, 0.0, 0.0, 0.0);
    } else if (background === "black") {
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
    } else if (background === "white") {
      gl.clearColor(1.0, 1.0, 1.0, 1.0);
    } else {
      gl.clearColor(0.0, 0.0, 0.0, 0.0);
    }

    this.#clearFramebuffer();
  }

  render() {
    this.#initEDL();

    const viewer = this.#viewer;
    const gl = viewer.gl;
    const camera = viewer.sceneContext.getActiveCamera();
    const { x, y } = viewer.renderContext.size;

    const visiblePointClouds = viewer.sceneContext.pointclouds.filter(
      (pc) => pc.visible
    );

    // COLOR & DEPTH PASS
    for (const pointcloud of visiblePointClouds) {
      const octreeSize = pointcloud.pcoGeometry.boundingBox.getSize(
        new Vector3()
      ).x;

      let material = pointcloud.material;
      material.weighted = false;
      material.useLogarithmicDepthBuffer = false;
      material.useEDL = true;

      material.screenWidth = x;
      material.screenHeight = y;
      material.uniforms.visibleNodes.value =
        pointcloud.material.visibleNodesTexture;
      material.uniforms.octreeSize.value = octreeSize;
      material.spacing = pointcloud.pcoGeometry.spacing;
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.#frameBuffer);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    viewer.pRenderer.render(visiblePointClouds, camera, null, {
      transparent: false,
    });

    gl.bindFramebuffer(gl.FRAMEBUFFER, null); // Unbind framebuffer after reading

    {
      // EDL PASS

      const uniforms = this.#edlMaterial.uniforms;

      uniforms.screenWidth.value = x;
      uniforms.screenHeight.value = y;

      const proj = camera.projectionMatrix;
      let projArray = new Float32Array(16);
      projArray.set(proj.elements);

      uniforms.uNear.value = camera.near;
      uniforms.uFar.value = camera.far;
      uniforms.uEDLColor.value = this.#renderTexture;
      //@ts-ignore
      uniforms.uProj.value = projArray;

      uniforms.edlStrength.value = viewer.edlStrength;
      uniforms.radius.value = viewer.edlRadius;
      uniforms.opacity.value = viewer.edlOpacity;

      Utils.screenPass.render(viewer.gl, this.#edlMaterial, viewer.shaderCache);
    }

    // render scene
    ObjectRenderer.renderScene(
      viewer.sceneContext.scene,
      this.#viewer.gl,
      camera,
      this.#viewer.shaderCache
    );
  }
}
