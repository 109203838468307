export function getAPIEndpoint(): string {
  let apiEndpoint = "https://api.skyqraft.com";

  switch (window.location.origin) {
    case "https://lidar.skyqraft.com":
      apiEndpoint = "https://api.skyqraft.com";
      break;
    case "https://dev.lidar.skyqraft.com":
      apiEndpoint = "https://api.dev.skyqraft.com";
      break;
    case "https://lidar.hawkeye.skyqraft.com":
      apiEndpoint = "https://api.hawkeye.skyqraft.com";
      break;
    case "https://lidar.atom.skyqraft.com":
      apiEndpoint = "https://api.atom.skyqraft.com";
      break;
    case "https://lidar.us.skyqraft.com":
      apiEndpoint = "https://api.us.skyqraft.com";
      break;
    default:
      if (window.location.origin.endsWith("arkion.co")) {
        apiEndpoint = window.location.origin.replace("lidar", "api");
      } else {
        apiEndpoint = `http://localhost:${
          process.env.REACT_APP_LOCAL_DEV ? 8081 : 8080
        }`;
      }
  }
  return apiEndpoint;
}

export function getMapURL(): string {
  let mapUrl = "https://map.skyqraft.com";

  switch (window.location.origin) {
    case "https://lidar.skyqraft.com":
      mapUrl = "https://map.skyqraft.com";
      break;
    case "https://dev.lidar.skyqraft.com":
      mapUrl = "https://dev.skyqraft.com";
      break;
    case "https://lidar.hawkeye.skyqraft.com":
      mapUrl = "https://hawkeye.skyqraft.com";
      break;
    case "https://lidar.atom.skyqraft.com":
      mapUrl = "https://atom.skyqraft.com";
      break;
    case "https://lidar.us.skyqraft.com":
      mapUrl = "https://us.skyqraft.com";
      break;
    default:
      if (window.location.origin.endsWith("arkion.co")) {
        mapUrl = window.location.origin.replace("lidar.", "");
      } else {
        return mapUrl;
      }
  }

  return mapUrl;
}
