import {
  ElevationGradientRepeat,
  Gradients,
  PointShape,
  PointSizeType,
  TreeType,
} from "potree/constants";
import { CanvasTexture, Color } from "potree/rendering/types";
import * as Shaders from "potree/rendering/shaders";
import { RawShaderMaterial } from "potree/rendering/material";
import {
  AdditiveBlending,
  AlwaysDepth,
  LessEqualDepth,
  LinearFilter,
  NearestFilter,
  NoBlending,
  RGBAFormat,
  RepeatWrapping,
  VertexColors,
} from "potree/rendering/constants";
import {
  DataTexture,
  recomputeDataTexture,
} from "potree/rendering/datatexture";
import { resourcePath } from "potree/paths";
import { loadTexture } from "potree/rendering/loaders";
import * as Utils from "../utils/utils";
import { PointCloudRenderAttributesIndexToValue } from "./octree.js";

export class PointCloudMaterial extends RawShaderMaterial {
  #pointSizeType = PointSizeType.FIXED;
  #shape = PointShape.SQUARE;
  #useClipBox = false;

  clipBoxes = [];
  clipBoxTypes = [];

  #weighted = false;
  #gradient = Gradients.SPECTRAL;
  #matcap = "matcap.jpg";

  #treeType;
  #useEDL = false;

  #activeAttributeName = null;

  constructor(parameters = {}) {
    super();

    this.type = "PointCloudMaterial";

    this.visibleNodesTexture = Utils.generateDataTexture(
      2048,
      1,
      new Color(1.0, 1.0, 1.0)
    );
    this.visibleNodesTexture.minFilter = NearestFilter;
    this.visibleNodesTexture.magFilter = NearestFilter;

    const pointSize = parameters.size ?? 1.0;
    const minSize = parameters.minSize ?? 2.0;
    const maxSize = parameters.maxSize ?? 50.0;
    this.#treeType = parameters.treeType ?? TreeType.OCTREE;

    this.gradientTexture = PointCloudMaterial.generateGradientTexture(
      this.#gradient
    );
    this.matcapTexture = PointCloudMaterial.generateMatcapTexture(this.#matcap);
    this.lights = false;

    this.defines = new Map();

    this.ranges = new Map();

    const [width, height] = [256, 1];
    const createTexture = (per_pixel, format) => {
      let data = new Uint8Array(width * per_pixel);
      let texture = new DataTexture(data, width, height, format);
      texture.magFilter = NearestFilter;
      texture.needsUpdate = true;

      return texture;
    };
    this.classificationTexture = createTexture(4, RGBAFormat);
    this.clearanceTexture = createTexture(4, RGBAFormat);
    this.classificationFlags = new Int32Array(256);
    this.volumeTexture = createTexture(4, RGBAFormat);

    this.attributes = {
      position: { type: "fv", value: [] },
      color: { type: "fv", value: [] },
      normal: { type: "fv", value: [] },
      intensity: { type: "f", value: [] },
      classification: { type: "f", value: [] },
      userData: { type: "f", value: [] },
      returnNumber: { type: "f", value: [] },
      numberOfReturns: { type: "f", value: [] },
      pointSourceID: { type: "f", value: [] },
      indices: { type: "fv", value: [] },
    };

    this.uniforms = {
      level: { type: "f", value: 0.0 },
      vnStart: { type: "f", value: 0.0 },
      spacing: { type: "f", value: 1.0 },
      blendHardness: { type: "f", value: 2.0 },
      blendDepthSupplement: { type: "f", value: 0.0 },
      fov: { type: "f", value: 1.0 },
      screenWidth: { type: "f", value: 1.0 },
      screenHeight: { type: "f", value: 1.0 },
      near: { type: "f", value: 0.1 },
      far: { type: "f", value: 1.0 },
      uColor: { type: "c", value: new Color(0xffffff) },
      uOpacity: { type: "f", value: 1.0 },
      size: { type: "f", value: pointSize },
      minSize: { type: "f", value: minSize },
      maxSize: { type: "f", value: maxSize },
      octreeSize: { type: "f", value: 0 },
      bbSize: { type: "fv", value: [0, 0, 0] },
      elevationRange: { type: "2fv", value: [0, 0] },

      clipBoxCount: { type: "f", value: 0 },
      clipPolygonCount: { type: "i", value: 0 },
      clipBoxTypes: { type: "iv", value: [] },
      clipBoxes: { type: "Matrix4fv", value: [] },
      clipPolygonVCount: { type: "iv", value: [] },
      clipPolygonVP: { type: "Matrix4fv", value: [] },

      visibleNodes: { type: "t", value: this.visibleNodesTexture },
      pcIndex: { type: "f", value: 0 },
      gradient: { type: "t", value: this.gradientTexture },
      classificationLUT: { type: "t", value: this.classificationTexture },
      clearanceLUT: { type: "t", value: this.clearanceTexture },
      classificationFlags: { type: "iv", value: this.classificationFlags },
      volumeLUT: { type: "t", value: this.volumeTexture },
      toModel: { type: "Matrix4f", value: [] },
      diffuse: { type: "fv", value: [1, 1, 1] },
      transition: { type: "f", value: 0.5 },

      intensityRange: { type: "fv", value: [Infinity, -Infinity] },

      intensity_gbc: { type: "fv", value: [1, 0, 0] },
      uRGB_gbc: { type: "fv", value: [1, 0, 0] },
      wRGB: { type: "f", value: 1 },
      wIntensity: { type: "f", value: 0 },
      wElevation: { type: "f", value: 0 },
      wClassification: { type: "f", value: 0 },
      wUserData: { type: "f", value: 0 },
      wReturnNumber: { type: "f", value: 0 },
      wSourceID: { type: "f", value: 0 },
      useOrthographicCamera: { type: "b", value: false },
      elevationGradientRepat: {
        type: "i",
        value: ElevationGradientRepeat.CLAMP,
      },
      clipTask: { type: "i", value: 1 },
      clipMethod: { type: "i", value: 1 },

      uExtraScale: { type: "f", value: 1 },
      uExtraOffset: { type: "f", value: 0 },
      uExtraRange: { type: "2fv", value: [0, 1] },

      uFilterReturnNumberRange: { type: "fv", value: [0, 7] },
      uFilterNumberOfReturnsRange: { type: "fv", value: [0, 7] },
      uFilterGPSTimeClipRange: { type: "fv", value: [0, 7] },
      uFilterPointSourceIDClipRange: { type: "fv", value: [0, 65535] },
      backfaceCulling: { type: "b", value: false },
    };

    this.classification = {};

    this.vertexShader = Shaders.get("pointcloud.vert");
    this.fragmentShader = Shaders.get("pointcloud.frag");

    this.vertexColors = VertexColors;

    this.updateShaderSource();
  }

  setActiveFilter(filter_num) {
    const filter = PointCloudRenderAttributesIndexToValue[filter_num];

    if (!!filter) {
      this.activeAttributeName = filter;
    }
  }

  setDefine(key, value) {
    if (value !== undefined && value !== null) {
      if (this.defines.get(key) !== value) {
        this.defines.set(key, value);
        this.updateShaderSource();
      }
    } else {
      this.removeDefine(key);
    }
  }

  removeDefine(key) {
    this.defines.delete(key);
  }

  updateShaderSource() {
    let vs = Shaders.get("pointcloud.vert");
    let fs = Shaders.get("pointcloud.frag");
    const definesString = this.getDefines();

    const vsVersionIndex = vs.indexOf("#version ");
    const fsVersionIndex = fs.indexOf("#version ");

    if (vsVersionIndex >= 0) {
      vs = vs.replace(/(#version .*)/, `$1\n${definesString}`);
    } else {
      vs = `${definesString}\n${vs}`;
    }

    if (fsVersionIndex >= 0) {
      fs = fs.replace(/(#version .*)/, `$1\n${definesString}`);
    } else {
      fs = `${definesString}\n${fs}`;
    }

    this.vertexShader = vs;
    this.fragmentShader = fs;

    if (this.opacity >= 1.0) {
      this.blending = NoBlending;
      this.transparent = false;
      this.depthTest = true;
      this.depthWrite = true;
      this.depthFunc = LessEqualDepth;
    } else if (!this.useEDL) {
      this.blending = AdditiveBlending;
      this.transparent = true;
      this.depthTest = false;
      this.depthWrite = true;
      this.depthFunc = AlwaysDepth;
    }

    if (this.#weighted) {
      this.blending = AdditiveBlending;
      this.transparent = true;
      this.depthTest = true;
      this.depthWrite = false;
    }

    this.needsUpdate = true;
  }

  getDefines() {
    let defines = [];

    if (this.pointSizeType === PointSizeType.FIXED) {
      defines.push("#define fixed_point_size");
    } else if (this.pointSizeType === PointSizeType.ATTENUATED) {
      defines.push("#define attenuated_point_size");
    } else if (this.pointSizeType === PointSizeType.ADAPTIVE) {
      defines.push("#define adaptive_point_size");
    }

    if (this.shape === PointShape.SQUARE) {
      defines.push("#define square_point_shape");
    } else if (this.shape === PointShape.CIRCLE) {
      defines.push("#define circle_point_shape");
    } else if (this.shape === PointShape.PARABOLOID) {
      defines.push("#define paraboloid_point_shape");
    }

    if (this.#useEDL) {
      defines.push("#define use_edl");
    }

    if (this.activeAttributeName) {
      let attributeName = this.activeAttributeName.replace(
        /[^a-zA-Z0-9]/g,
        "_"
      );

      defines.push(`#define color_type_${attributeName}`);
    }

    if (this.#treeType === TreeType.OCTREE) {
      defines.push("#define tree_type_octree");
    } else if (this.#treeType === TreeType.KDTREE) {
      defines.push("#define tree_type_kdtree");
    }

    if (this.#weighted) {
      defines.push("#define weighted_splats");
    }

    defines.push.apply(Object.values(this.defines));

    return defines.join("\n");
  }

  setClipBoxes(clipBoxes) {
    if (!clipBoxes) {
      return;
    }

    let needsUpdate =
      this.clipBoxes.length !== clipBoxes.length &&
      (clipBoxes.length === 0 || this.clipBoxes.length === 0);

    this.uniforms.clipBoxCount.value = this.clipBoxes.length;
    this.clipBoxes = clipBoxes;

    if (needsUpdate) {
      this.updateShaderSource();
    }

    this.uniforms.clipBoxes.value = new Float32Array(
      this.clipBoxes.length * 16
    );

    for (let i = 0; i < this.clipBoxes.length; i++) {
      let box = clipBoxes[i];

      this.uniforms.clipBoxes.value.set(box.inverse.elements, 16 * i);
    }

    for (let i = 0; i < this.uniforms.clipBoxes.value.length; i++) {
      if (Number.isNaN(this.uniforms.clipBoxes.value[i])) {
        this.uniforms.clipBoxes.value[i] = Infinity;
      }
    }
  }

  setClipBoxTypes(clipBoxTypes) {
    if (!clipBoxTypes) {
      return;
    }

    const doUpdate =
      this.clipBoxTypes.length !== clipBoxTypes.length &&
      (clipBoxTypes.length === 0 || this.clipBoxTypes.length === 0);

    this.uniforms.clipBoxCount.value = this.clipBoxTypes.length;
    this.clipBoxTypes = clipBoxTypes;

    if (doUpdate) {
      this.updateShaderSource();
    }
    this.uniforms.clipBoxTypes = new Int32Array(this.clipBoxTypes.length);

    for (let i = 0; i < this.clipBoxTypes.length; i++) {
      const type = clipBoxTypes[i];
      this.uniforms.clipBoxTypes[i] = type;
    }
  }

  get gradient() {
    return this.#gradient;
  }

  set gradient(value) {
    if (this.#gradient !== value) {
      this.#gradient = value;
      this.gradientTexture = PointCloudMaterial.generateGradientTexture(
        this.#gradient
      );
      this.uniforms.gradient.value = this.gradientTexture;
    }
  }

  get matcap() {
    return this.#matcap;
  }

  set matcap(value) {
    if (this.#matcap !== value) {
      this.#matcap = value;
      this.matcapTexture = PointCloudMaterial.generateMatcapTexture(
        this.#matcap
      );
      this.uniforms.matcapTextureUniform.value = this.matcapTexture;
    }
  }
  get useOrthographicCamera() {
    return this.uniforms.useOrthographicCamera.value;
  }

  set useOrthographicCamera(value) {
    this.uniforms.useOrthographicCamera.value = value;
  }
  get backfaceCulling() {
    return this.uniforms.backfaceCulling.value;
  }

  set backfaceCulling(value) {
    this.uniforms.backfaceCulling.value = value;
  }

  recomputeClassification() {
    const classifications = this.classification;
    const data = this.classificationTexture.image.data;
    data.fill(0);

    for (const classification of Object.values(classifications)) {
      const color = classification.color;
      const r = 255 * color[0];
      const g = 255 * color[1];
      const b = 255 * color[2];
      const a = classification.visible ? 255 * color[3] : 0;

      for (const entry of classification.entries) {
        const i = entry * 4;
        data[i + 0] = r;
        data[i + 1] = g;
        data[i + 2] = b;
        data[i + 3] = a;
      }
    }

    this.classificationTexture.needsUpdate = true;
  }
  recomputeClearance() {
    const clearance = this.clearance;
    const data = this.clearanceTexture.image.data;

    if (recomputeDataTexture(clearance, data, 256, [1.0, 1.0, 1.0, 1.0])) {
      this.clearanceTexture.needsUpdate = true;
    }
  }
  recomputeClassificationFlags(highlighted_entries, large_points_classes) {
    this.classificationFlags.fill(0);

    // Bit flags.
    const CLASS_FLAG_HIGHLIGHT = 1 << 0;
    const CLASS_FLAG_LARGE_POINT = 1 << 1;

    // Set highlighted, these always display class color.
    for (const entry of highlighted_entries) {
      this.classificationFlags[entry] |= CLASS_FLAG_HIGHLIGHT;
    }

    // Set large point, these points are displayed much large (used mostly for ground coverage).
    for (const entry of large_points_classes) {
      this.classificationFlags[entry] |= CLASS_FLAG_LARGE_POINT;
    }
  }
  updateVolumeTypeColor(volume_colors) {
    const data = this.volumeTexture.image.data;
    data.fill(0);

    for (const [i, color] of volume_colors.entries()) {
      const data_i = i * 4;
      data[data_i + 0] = color.r * 255.0;
      data[data_i + 1] = color.g * 255.0;
      data[data_i + 2] = color.b * 255.0;
      data[data_i + 3] = 255.0;
    }
    this.volumeTexture.needsUpdate = true;
  }

  get spacing() {
    return this.uniforms.spacing.value;
  }

  set spacing(value) {
    if (this.uniforms.spacing.value !== value) {
      this.uniforms.spacing.value = value;
    }
  }

  get useClipBox() {
    return this.#useClipBox;
  }

  set useClipBox(value) {
    if (this.#useClipBox !== value) {
      this.#useClipBox = value;
      this.updateShaderSource();
    }
  }

  get clipTask() {
    return this.uniforms.clipTask.value;
  }

  set clipTask(mode) {
    this.uniforms.clipTask.value = mode;
  }

  get elevationGradientRepat() {
    return this.uniforms.elevationGradientRepat.value;
  }

  set elevationGradientRepat(mode) {
    this.uniforms.elevationGradientRepat.value = mode;
  }

  get clipMethod() {
    return this.uniforms.clipMethod.value;
  }

  set clipMethod(mode) {
    this.uniforms.clipMethod.value = mode;
  }

  get weighted() {
    return this.#weighted;
  }

  set weighted(value) {
    if (this.#weighted !== value) {
      this.#weighted = value;
      this.updateShaderSource();
    }
  }

  get fov() {
    return this.uniforms.fov.value;
  }

  set fov(value) {
    this.uniforms.fov.value = value;
  }

  get screenWidth() {
    return this.uniforms.screenWidth.value;
  }

  set screenWidth(value) {
    this.uniforms.screenWidth.value = value;
  }

  get screenHeight() {
    return this.uniforms.screenHeight.value;
  }

  set screenHeight(value) {
    this.uniforms.screenHeight.value = value;
  }

  get near() {
    return this.uniforms.near.value;
  }

  set near(value) {
    this.uniforms.near.value = value;
  }

  get far() {
    return this.uniforms.far.value;
  }

  set far(value) {
    this.uniforms.far.value = value;
  }

  get opacity() {
    return this.uniforms.uOpacity.value;
  }

  set opacity(value) {
    if (this.uniforms && this.uniforms.uOpacity) {
      if (this.uniforms.uOpacity.value !== value) {
        this.uniforms.uOpacity.value = value;
        this.updateShaderSource();
      }
    }
  }

  get activeAttributeName() {
    return this.#activeAttributeName;
  }

  set activeAttributeName(value) {
    if (this.#activeAttributeName !== value) {
      this.#activeAttributeName = value;

      this.updateShaderSource();
    }
  }

  get pointSizeType() {
    return this.#pointSizeType;
  }

  set pointSizeType(value) {
    if (this.#pointSizeType !== value) {
      this.#pointSizeType = value;
      this.updateShaderSource();
    }
  }

  get useEDL() {
    return this.#useEDL;
  }

  set useEDL(value) {
    if (this.#useEDL !== value) {
      this.#useEDL = value;
      this.updateShaderSource();
    }
  }

  get color() {
    return this.uniforms.uColor.value;
  }

  set color(value) {
    this.uniforms.uColor.value.copy(value);
  }

  get shape() {
    return this.#shape;
  }

  set shape(value) {
    this.#shape = value;
    this.updateShaderSource();
  }

  get treeType() {
    return this.#treeType;
  }

  set treeType(value) {
    if (this.#treeType !== value) {
      this.#treeType = value;
      this.updateShaderSource();
    }
  }

  get bbSize() {
    return this.uniforms.bbSize.value;
  }

  set bbSize(value) {
    this.uniforms.bbSize.value = value;
  }

  get size() {
    return this.uniforms.size.value;
  }

  set size(value) {
    this.uniforms.size.value = value;
  }

  get minSize() {
    return this.uniforms.minSize.value;
  }

  set minSize(value) {
    this.uniforms.minSize.value = value;
  }

  get elevationRange() {
    return this.uniforms.elevationRange.value;
  }

  set elevationRange(value) {
    this.uniforms.elevationRange.value = value;
  }

  get heightMin() {
    return this.uniforms.elevationRange.value[0];
  }

  set heightMin(value) {
    this.elevationRange = [value, this.elevationRange[1]];
  }

  get heightMax() {
    return this.uniforms.elevationRange.value[1];
  }

  set heightMax(value) {
    this.elevationRange = [this.elevationRange[0], value];
  }

  get transition() {
    return this.uniforms.transition.value;
  }

  set transition(value) {
    this.uniforms.transition.value = value;
  }

  get intensityRange() {
    return this.uniforms.intensityRange.value;
  }

  set intensityRange(value) {
    if (!(value instanceof Array && value.length === 2)) {
      return;
    }

    this.uniforms.intensityRange.value = value;
  }

  get intensityGamma() {
    return this.uniforms.intensity_gbc.value[0];
  }

  set intensityGamma(value) {
    this.uniforms.intensity_gbc.value[0] = value;
  }

  get intensityContrast() {
    return this.uniforms.intensity_gbc.value[2];
  }

  set intensityContrast(value) {
    this.uniforms.intensity_gbc.value[2] = value;
  }

  get intensityBrightness() {
    return this.uniforms.intensity_gbc.value[1];
  }

  set intensityBrightness(value) {
    this.uniforms.intensity_gbc.value[1] = value;
  }

  get rgbGamma() {
    return this.uniforms.uRGB_gbc.value[0];
  }

  set rgbGamma(value) {
    this.uniforms.uRGB_gbc.value[0] = value;
  }

  get rgbContrast() {
    return this.uniforms.uRGB_gbc.value[2];
  }

  set rgbContrast(value) {
    this.uniforms.uRGB_gbc.value[2] = value;
  }

  get rgbBrightness() {
    return this.uniforms.uRGB_gbc.value[1];
  }

  set rgbBrightness(value) {
    this.uniforms.uRGB_gbc.value[1] = value;
  }

  get extraGamma() {
    return this.uniforms.uExtraGammaBrightContr.value[0];
  }

  set extraGamma(value) {
    this.uniforms.uExtraGammaBrightContr.value[0] = value;
  }

  get extraBrightness() {
    return this.uniforms.uExtraGammaBrightContr.value[1];
  }

  set extraBrightness(value) {
    this.uniforms.uExtraGammaBrightContr.value[1] = value;
  }

  get extraContrast() {
    return this.uniforms.uExtraGammaBrightContr.value[2];
  }

  set extraContrast(value) {
    this.uniforms.uExtraGammaBrightContr.value[2] = value;
  }

  getRange(attributeName) {
    return this.ranges.get(attributeName);
  }

  setRange(attributeName, newRange) {
    this.ranges.set(attributeName, newRange);
  }

  get extraRange() {
    return this.uniforms.uExtraRange.value;
  }

  set extraRange(value) {
    if (!(value instanceof Array && value.length === 2)) {
      return;
    }

    this.uniforms.uExtraRange.value = value;
  }

  get weightRGB() {
    return this.uniforms.wRGB.value;
  }

  set weightRGB(value) {
    this.uniforms.wRGB.value = value;
  }

  get weightIntensity() {
    return this.uniforms.wIntensity.value;
  }

  set weightIntensity(value) {
    this.uniforms.wIntensity.value = value;
  }

  get weightElevation() {
    return this.uniforms.wElevation.value;
  }

  set weightElevation(value) {
    this.uniforms.wElevation.value = value;
  }

  get weightClassification() {
    return this.uniforms.wClassification.value;
  }

  set weightClassification(value) {
    this.uniforms.wClassification.value = value;
  }

  get weightUserData() {
    return this.uniforms.wUserData.value;
  }

  set weightUserData(value) {
    this.uniforms.wUserData.value = value;
  }

  get weightReturnNumber() {
    return this.uniforms.wReturnNumber.value;
  }

  set weightReturnNumber(value) {
    this.uniforms.wReturnNumber.value = value;
  }

  get weightSourceID() {
    return this.uniforms.wSourceID.value;
  }

  set weightSourceID(value) {
    this.uniforms.wSourceID.value = value;
  }

  static generateGradientTexture(gradient) {
    const size = 64;

    // create canvas
    let canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;

    // get context
    let context = canvas.getContext("2d");

    // draw gradient
    context.rect(0, 0, size, size);
    let ctxGradient = context.createLinearGradient(0, 0, size, size);

    for (let i = 0; i < gradient.length; i++) {
      const step = gradient[i];

      ctxGradient.addColorStop(step[0], "#" + step[1].getHexString());
    }

    context.fillStyle = ctxGradient;
    context.fill();

    let texture = new CanvasTexture(canvas);
    texture.needsUpdate = true;
    texture.minFilter = LinearFilter;
    texture.wrap = RepeatWrapping;
    texture.repeat = 2;

    return texture;
  }

  static generateMatcapTexture(matcap) {
    const url = new URL(resourcePath + "/textures/matcap/" + matcap).href;
    let texture = loadTexture(url);
    texture.magFilter = texture.minFilter = LinearFilter;
    texture.needsUpdate = true;
    // PotreeConverter_1.6_2018_07_29_windows_x64\PotreeConverter.exe autzen_xyzrgbXYZ_ascii.xyz -f xyzrgbXYZ -a RGB NORMAL -o autzen_xyzrgbXYZ_ascii_a -p index --overwrite
    // Switch matcap texture on the fly : viewer.sceneContext.pointclouds[0].material.matcap = 'matcap1.jpg';
    // For non power of 2, use LinearFilter and dont generate mipmaps, For power of 2, use NearestFilter and generate mipmaps : matcap2.jpg 1 2 8 11 12 13
    return texture;
  }

  disableEvents() {
    if (this._hiddenListeners === undefined) {
      this._hiddenListeners = this._listeners;
      this._listeners = {};
    }
  }

  enableEvents() {
    this._listeners = this._hiddenListeners;
    this._hiddenListeners = undefined;
  }
}
