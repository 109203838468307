import { Euler, Matrix4, Quaternion, Vector3 } from "./mathtypes";
import { Viewer } from "./potree";
import { getMousePointCloudIntersection } from "./utils/utils";

export interface TargetLocation {
  type: "NONE" | "IMAGE" | "COORD";

  lat?: number;
  lng?: number;
  alt?: number;
  pitch?: number;
  yaw?: number;

  imageId?: number;

  godBeamMatrix?: Matrix4;
}

/**
 *   Time between attempts to place the god beam.
 */
const BEAM_PLACEMENT_TIMEOUT = 200;

export function traceGodBeamLocationFromTransform(
  viewer: Viewer,
  transform: { position: Vector3; rotation: Euler }
) {
  // Take a copy of the normal perspective camera
  // Ensures we get a position in the center of the user's view.
  const camera = viewer.sceneContext.cameraP.clone();
  // Replace it's heading with the target image's.
  camera.position.set(
    transform.position.x,
    transform.position.y,
    transform.position.z
  );
  camera.rotation.order = "ZXY";
  camera.rotation.set(
    transform.rotation.x,
    transform.rotation.y,
    transform.rotation.z
  );
  // Need to update to apply our changes.
  camera.updateMatrixWorld();

  let timeoutHandle = null;

  // Clean ourselves up if the scene context has changed.
  // Target locations can't carry over between scenes so there's no point in continuing.
  const cancel = () => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }

    viewer.removeEventListener("scene_context_changed", cancel);
  };
  viewer.addEventListener("scene_context_changed", cancel);

  const attempt = (attempt_number: number) => {
    // Center of screen.
    const mouse = viewer.renderContext.size.clone().multiplyScalar(0.5);

    // Get the point at the mouse position (center of screen so the one right ahead in the middle).
    const intersection = getMousePointCloudIntersection(
      mouse,
      camera,
      viewer,
      viewer.sceneContext.pointclouds,
      { pickClipped: false }
    );

    if (intersection) {
      // Turn the position into a matrix and push it into the `godBeamMatrix` of our targetLocation.
      // This is then rendered by lineRenderer.ts.
      const matrix = new Matrix4().compose(
        intersection.location,
        new Quaternion(),
        new Vector3(1.0, 1.0, 1.0)
      );
      viewer.targetLocation.godBeamMatrix = matrix;

      viewer.sceneContext.view.lookAt(intersection.location);

      viewer.targetLocation.yaw = viewer.sceneContext.view.yaw;
      viewer.targetLocation.pitch = viewer.sceneContext.view.pitch;

      timeoutHandle = null;
    } else if (attempt_number < 64) {
      // Let's not try forever.
      // Linearly increase the timeout time between attempts.
      // At most this goes to ~2.5 secs between before we give up.
      // In cases where the internet is EXTREMELY bad this might fail.
      timeoutHandle = setTimeout(
        attempt,
        BEAM_PLACEMENT_TIMEOUT * (1 + attempt_number / 5),
        attempt_number + 1
      );
    }
  };

  timeoutHandle = setTimeout(attempt, BEAM_PLACEMENT_TIMEOUT, 0);
}
