import styled from "styled-components";

// YOINKED FROM ReviewTools/PrimaryAction
// REMOVE IN APP MERGE
export const backgroundColors = {
  disabled: "#939393",
  grey: "#939393",
  green: "#24BE4F",
  lightGreen: "#36D71C",
  red: "#E41616",
  yellow: "#FFB800",
};

export const Circle = styled.button`
	border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	padding: 0;
	margin: 0;
	transition: .3s;

	&[disabled] {
		background-color: ${backgroundColors.disabled};
	}
	&:not([disabled]) {
		background-color: ${(props) => backgroundColors[props.color]};
		cursor: pointer;

		&:hover {
			cursor: pointer;
			transform: scale(1.05);
		}
`;
// END YOINKING FROM ReviewTools/PrimaryAction

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: "Manrope", sans-serif;
`;
