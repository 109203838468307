import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Stack,
  Alert,
  Paper,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BASE_URI } from "config";
import { Loading } from "react/views";
import { validateEmail } from "utils";

interface ILoginResponse {
  success?: boolean;
  tooManyAttempts?: boolean;
  changePassword?: boolean;
  redirect?: {
    url: string;
    message: string;
  };
  challenge?: {
    name: string;
    session: string;
    email?: string;
    phone_number?: string;
    username?: string;
  };
}

export default function Login({ setLoggedIn, setRedirectLink }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [messageLevel, setMessageLevel] = useState<
    "error" | "info" | "success" | "warning"
  >("info");

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setSigningIn(true);
    axios
      .post<ILoginResponse>(
        `${BASE_URI}/auth/login`,
        {
          email: data.get("email"),
          password: data.get("password"),
        },
        { withCredentials: true }
      )
      .then(({ data }) => {
        setSigningIn(false);

        if (data.success) {
          setLoggedIn(true);
        } else if (data.redirect) {
          setMessage(data.redirect.message);
          setMessageLevel("info");
          setTimeout(() => {
            window.location.href = data.redirect.url;
          }, 1500);
        } else if (data.tooManyAttempts) {
          console.log("Reset password");
          navigate("/reset?too_many_attempts=true");
        } else if (
          !!data.challenge &&
          data.challenge.name === "NEW_PASSWORD_REQUIRED"
        ) {
          navigate(
            `change?session=${data.challenge.session}&email=${data.challenge.email}`
          );
        } else if (
          !!data.challenge &&
          ["SMS_MFA", "SOFTWARE_TOKEN_MFA"].includes(data.challenge.name)
        ) {
          if (data.challenge.phone_number) {
            navigate(
              `mfa?session=${data.challenge.session}&phone=${data.challenge.phone_number}&username=${data.challenge.username}`
            );
          } else {
            navigate(
              `mfa?session=${data.challenge.session}&username=${data.challenge.username}`
            );
          }
        }
      })
      .catch((err) => {
        try {
          if (err.response.data.redirect) {
            setMessage(err.response.data.redirect.message);
            setMessageLevel("info");
            setTimeout(() => {
              window.location.href = err.response.data.redirect.url;
            }, 1500);
          }
          setSigningIn(false);
          setMessage(err.response.data);
          setMessageLevel("error");
        } catch (e) {
          setSigningIn(false);
          setMessage("Failed to login");
          setMessageLevel("error");
        }
      });
  }

  useEffect(() => {
    if (searchParams.has("redirect")) {
      setRedirectLink(searchParams.get("redirect") as string);
    } else {
      setRedirectLink("/");
    }

    axios.get("/auth", { withCredentials: true }).then(({ data }) => {
      if (data?.loggedIn) {
        setLoggedIn(true);
      }
    });
  }, []);

  const emailValid = validateEmail(email.toLowerCase());

  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Paper sx={{ p: 2 }}>
        {message && (
          <Alert
            sx={{ mb: 1 }}
            severity={messageLevel}
            onClose={() => {
              setMessage("");
            }}
          >
            {message}
          </Alert>
        )}

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={!emailValid && email.length > 0}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={signingIn && <Loading relative size={20} color="white" />}
            disabled={signingIn || !emailValid}
          >
            {signingIn ? "Signing" : "Sign"} In
          </Button>
          <Stack alignItems="center">
            <Link style={{ color: "#0078EB" }} to="reset">
              Forgot password?
            </Link>
          </Stack>
          <Alert severity="info" sx={{ mt: 2 }}>
            Skyqraft has become Arkion. You can learn more on our website{" "}
            <a href="https://www.arkion.co/resources/the-company-you-knew-as-skyqraft-is-now-arkion">
              arkion.co
            </a>
            .
          </Alert>
        </Box>
      </Paper>
    </Stack>
  );
}
